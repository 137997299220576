<template>
  <div>
      <BigMenu />
    <nav
      class="
        d-flex d-sm-none
        justify-content-between
        align-items-center
        px-4
        py-2
        shadow
      "
      style="height: 6rem"
    >
      <div>
        <a
          href="/"
          class="d-flex align-items-center"
          style="text-decoration: none"
        >
          <img
            :src="JDLogo"
            style="height: 7rem; width: auto"
            alt="Jadecapes"
          />
        </a>
      </div>
        <div class="custom-hamburger-menu" @click="openBigMenu">
                <div class="bar1"></div>
                <div class="bar2"></div>
                <div class="bar3"></div>
        </div>
    </nav>
  </div>
</template>

<script>
import JDLogo from "@/assets/JadeCaps_logo_F-01.png";
import BigMenu from "@/components/BigMenu/BigMenu.vue";

export default {
  data() {
    return {
      JDLogo: JDLogo,
    };
  },
  methods: {
    logOut(e) {
      e.preventDefault();
      console.log("logout");
      document.getElementById("id01").style.display = "none";
      localStorage.removeItem("app_access_token");
      localStorage.removeItem("app_refresh_token");
      this.$router.push("/");
    },
    searchByCity(e) {
      this.$router.push(`/property?city=${e.target.value.split(",")[0]}`);
      this.$router.go();
      console.log(e.target.value);
    },
    changeIsSignedIn() {
      return;
    },
    openBigMenu() {
      document.getElementById("custom-big-menu").classList.add("active");
    },
  },
  components:{
    BigMenu
  },
  mounted() {
    // this.useGetUserDetails();
    if (localStorage.getItem("app_access_token")) {
      this.isSignedIn = true;
    }
    new google.maps.places.Autocomplete(
      document.getElementById("navlocation"),
      this.options
    );
  },
};
</script>

<style lang="scss" scoped>
nav {
  display: flex;
  .custom-container {
    width: 80%;
  }
  .custom-details {
    width: 40%;
    img {
      height: 1.2rem;
      width: auto;
    }
    .custom-notification {
      height: 1.2rem;
      width: 1.2rem;
      border-radius: 50%;
      top: -0.3rem;
      right: -0.5rem;
    }
    .custom-image {
      img {
        height: 2.2rem;
        width: 2.2rem;
        object-fit: cover;
        border-radius: 2rem;
      }
    }
  }
  .custom-search {
    width: 60%;
    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid rgba(1, 1, 1, 0.2);
      outline: none;
      margin-left: 0.4rem;
      &::placeholder {
        opacity: 0.3;
        font-weight: 600;
        color: #111;
      }
    }
    img {
      height: 1.2rem;
      width: 1.2rem;
      opacity: 0.3;
    }
  }
  .custom-router-link {
    background-color: #3c3ce1;
    padding: 10px 20px;
    border-radius: 50px;
    text-decoration: none;
    color: #fff;
  }
}
.custom-hover-item {
  color: #333;
  text-decoration: none;
  font-weight: 500;
  transition: 0.3s ease;
  &:hover {
    color: #3c3ce1;
    text-decoration: underline;
  }
}
.special-square {
  height: 2rem;
  width: 2rem;
  background-color: #3c3ce1;
}
#dropdown-special-2{
  background-color: transparent;
  color: #333;
}
.custom-hamburger-menu{
    height: 1.8rem;
    width: 3rem;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    .bar1{
        width: 60%;
        height: 4px;
        border: 1px solid #333;
        background-color: transparent;
        border-radius: 6px;
    }
    .bar2{
        width: 100%;
        height: 4px;
        border: 1px solid #333;
        background-color: #333;
        border-radius: 6px;
    }
    .bar3{
        width: 100%;
        height: 4px;
        border: 1px solid #333;
        background-color: #333;
        border-radius: 6px;
    }
}

</style>

