import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.css'
import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueCarousel from 'vue-carousel';
import Vue2TouchEvents from 'vue2-touch-events'
import Toasted from 'vue-toasted';
import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import vSelect from "vue-select";

import Tawk from 'vue-tawk'
import "vue-select/dist/vue-select.css";
Vue.component("v-select", vSelect);

library.add(faUserSecret,faChevronDown, faChevronUp)

Vue.component('font-awesome-icon', FontAwesomeIcon)
  
Vue.use(Tawk, {
    tawkSrc: 'https://embed.tawk.to/61febf9f9bd1f31184db2054/1fr5hp375'
})

Vue.use(Toasted)






Vue.config.productionTip = false
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vue2TouchEvents)
Vue.use(VueCarousel);



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
