<template>
    <div class="d-flex align-items-start justify-content-between flex-wrap w-100 position-relative h-100">
        <div v-for="(item, index) in properties" :key="index" class="custom-width">
            <div class="custom-card-container d-flex justify-content-center align-items-center position-relative">
                <Property :property="item" :durationType="durationType" />
            </div>
        </div>
        <div class="w-100 d-flex m-2 justify-content-end">
            <b-pagination
                v-model="currentPage"
                :total-rows="rows"
                :per-page="perPage"
            ></b-pagination>
        </div>
    </div>
</template>
<script>
import Property from '../Property/Property.vue';
export default {
    data() {
        return {
            currentPage: 1,
            rows: this.totalRows,
            perPage:10,
        }
    },
    methods:{
    },
    watch:{
        currentPage(){
            this.setNextPage(this.currentPage)
            this.getListingsFromParam()
        }
    },
    props:["properties","hasNextPage","setNextPage","buttonLoading","page","getListingsFromParam", "totalRows", "durationType"],
    components: {
        Property
    },
}
</script>
<style lang="scss" scoped>
    .custom-card-container {
        width: 100%;
    }
    .custom-width{
        width:50%;
    }
    .custom-load-button-class{
        padding: 0.5rem 1rem;
        border-radius: 5px;
        background-color: #6345FB;
        color: #fff;
        cursor: pointer;
    }


    @media only screen and (max-width: 576px) {
        .custom-width {
            width: 100% !important;
        }
    }
</style>