import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Listings from '../views/Listings.vue';
import Individual from '../views/Individual.vue';

Vue.use(VueRouter)

function guardMyroute(to, from, next){
  var isAuthenticated= false;
  //this is just an example. You will have to find a better or 
  // centralised way to handle you localstorage data handling 
  if(localStorage.getItem('app_access_token'))
    isAuthenticated = true;
  else
    isAuthenticated= false;
  if(isAuthenticated) 
  {
    next(); // allow to enter route
  } 
  else
  {
    next('/login'); // go to '/login';
  }
}

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/property',
    name: 'Listings',
    component: Listings,
  },
  {
    path: '/property/:id',
    name: 'Individual',
    component: Individual,
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  {
    path: '/forgot-password',
    name: 'Forgot Password',
    component: () => import(/* webpackChunkName: "about" */ '../views/ForgotPassword.vue')
  },
  {
    path: '/pages/resetpassword',
    name: 'Reset Password',
    component: () => import(/* webpackChunkName: "about" */ '../views/ResetPassword.vue')
  },
  
  {
    path: '/booking-summary/',
    name: 'Booking Summary',
    props: {
      header: true,
      content: true
    },
    component: () => import(/* webpackChunkName: "about" */ '../components/BookingSummary/BookingSummary.vue')
  },
  {
    path: '/confirmation',
    name: 'Confirmation',
    component: () => import(/* webpackChunkName: "about" */ '../components/Confirmation/Confirmation.vue')
  },
  {
    path: '/failed',
    name: 'PaymentFailed',
    component: () => import(/* webpackChunkName: "about" */ '../components/PaymentFailed/PaymentFailed.vue')
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: () => import(/* webpackChunkName: "about" */ '../components/Checkout/Checkout.vue')
  },
  {
    path: '/reservations',
    name: 'Reservations',
    component: () => import(/* webpackChunkName: "about" */ '../components/Reservations/Reservations.vue'),
    beforeEnter: guardMyroute,
  },
  {
    path: '/verify-email',
    name: 'Verify Email',
    component: () => import(/* webpackChunkName: "about" */ '../views/VerifyEmail.vue')
  },
  {
    path: '/faqs',
    name: 'FAQs',
    component: () => import(/* webpackChunkName: "about" */ '../views/FAQs.vue')
  },
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "about" */ '../views/Profile.vue'),
    beforeEnter: guardMyroute,
  },
  {
    path: '*',
    name: 'NotFound',
    component: () => import(/* webpackChunkName: "about" */ '../views/404NotFound.vue')
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
