const {default: axios}=require("axios")

const url = 'https://jadecapsapi.bits42.tech/api/v1/owners/'
const authenticationUrl = `https://jadecapsapi.bits42.tech/`



axios.interceptors.request.use((config) => {
    const token = localStorage.getItem("app_access_token");
    if(token){
      config.headers['Authorization'] = 'Bearer ' + token;
    }
    
  
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
    (error) => {
      Promise.reject(error);
    }
  );
  
  
  axios.interceptors.response.use((response) => {
    return response;
  },
  
  function(error)
  {
    const originalRequest = error.config;
    if(error.response.status === 401 && !originalRequest._retry && localStorage.getItem('app_refresh_token')){
      console.log(localStorage.getItem('app_refresh_token'))
      error.config._retry = true;
  
  
      return axios.post(`${authenticationUrl}api/token/refresh/`,{
        refresh : localStorage.getItem('app_refresh_token')
      })
  
      .then(res => {
        console.log(res)
        //Setting up access token in Local Storage
        localStorage.setItem('app_access_token', res.data.access)
        //Changing Authorization
        axios.defaults.headers.common['Authorization'] = 'Bearer '+localStorage.getItem('app_access_token');
        return axios(originalRequest)
      })
  }
  return Promise.reject(error);
  }
  );
  
  

export const getListingsByCity = async (query, pageNo) => {
    const res = await axios.post(`${url}listing_search/${pageNo}/`, query);
    console.log(res.data)
    return res.data;
}
export const createPayment = async (apiBody) => {
    const res = await axios.post(`${url}create_payment_order/`, apiBody);
    console.log(res.data)
    return res.data;
}
export const verifyEmail = async (apiBody) => {
    const res = await axios.post(`https://jadecapsapi.bits42.tech/api/v1/accounts/verify-again/`, apiBody);
    console.log(res.data)
    return res.data;
}
export const paymentSuccess = async (apiBody) => {
    const res = await axios.post(`${url}payment_success/`, apiBody);
    console.log(res.data)
    return res.data;
}
export const addReservation = async (apiBody) => {
    const res = await axios.post(`${url}create-reservation/`, apiBody);
    console.log(res.data)
    return res.data;
}
export const addGuest = async (apiBody, id) => {
    const res = await axios.post(`${url}guests/${id}/`, apiBody);
    console.log(res.data)
    return res.data;
}

export const getPropertyById = async (id) => {
    const res = await axios.get(`${url}indiv_listing/${id}/`);
    return res.data;
}
export const getBookingSummary = async (id) => {
    const res = await axios.get(`${url}booking-summary/${id}/`);
    return res.data;
}
/////////////////// forgot pwd
export const forgotPwd = async(apiBody) => {
  const apiUrl = `${authenticationUrl}api/v1/accounts/password_reset/`
  console.log('API Body:', apiBody);

    const res = await axios.post(apiUrl, apiBody);
    console.log(res.data);
    return res;
}
/////////////////// forgot pwd reset
export const resetPwd = async(apiBody) => {
  console.log("yo")

  const apiUrl = `${authenticationUrl}api/v1/accounts/password_reset/confirm/`
  console.log('API Body:', apiBody);

    const res = await axios.post(apiUrl, apiBody);
    console.log(res.data);
    return res;
}

export const getTrendingLocations =async () => {
    const res = await axios.get(`${url}trending_locations/`);
    return res.data;
}

export const loginUser = async (apiBody) => {
    const newUrl = 'https://jadecapsapi.bits42.tech/api/v1/accounts/api/token/'
    const res = await axios.post(newUrl, apiBody);
    return res;
}

export const signupUser = async (apiBody) => {
    const newUrl = 'https://jadecapsapi.bits42.tech/api/v1/accounts/register/'
    try {
        const res = await axios.post(newUrl, apiBody);
        return res;
    } catch (error) {
        console.log(error)
        return error;
    }
}

export const postBookingSummary = async (apiBody) => {
  const res = await axios.post(`https://jadecapsapi.bits42.tech/api/v1/owners/booking-summary/${apiBody.house_id}/`, apiBody);
  return res;
}

export const getUserDetails = async() => {
  console.log('Entering user details');
  const apiUrl = `${authenticationUrl}api/v1/accounts/getUser`;
  const res = await axios.get(apiUrl);
  return res;
}

export const getRentersBookings = async() => {
  console.log('Entering renters bookings');
  const apiUrl = `${authenticationUrl}renter-bookings/`;
  const res = await axios.get("https://jadecapsapi.bits42.tech/api/v1/owners/renter-bookings/");
  return res;
}