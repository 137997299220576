<template>
  <div class="loader-body">
    <ul>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
      <li></li>
    </ul>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.loader-body{
  height: 100vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1322;
  background-color: #fff;

ul{
  width: 130px;
  height: 130px;
  list-style-type: none;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
    ul li{
    width: 30px;
    height: 30px;
    margin: 2px;
    background-color: transparent;
    animation: loading 0.8s infinite;

    }

    ul li:nth-child(5){
    opacity: 0;
    }
    ul li:nth-child(1){
    animation-delay: 0.1s;
    }
    ul li:nth-child(2){
    animation-delay: 0.2s;
    }
    ul li:nth-child(3){
    animation-delay: 0.3s;
    }
    ul li:nth-child(6){
    animation-delay: 0.4s;
    }
    ul li:nth-child(9){
    animation-delay: 0.5s;
    }
    ul li:nth-child(8){
    animation-delay: 0.6s;
    }
    ul li:nth-child(7){
    animation-delay: 0.7s;
    }
    ul li:nth-child(4){
    animation-delay: 0.8s;
    }
}

@keyframes loading{
  1%{
    background-color: #0086b3;
  }
}
</style>