<template>
  <div class="custom-footer">
    <div class="custom-container">
      <div class="custom-class-responsive h-50 w-full d-flex justify-content-between">
        <div class="font-size-big-small">Jadecaps</div>
        <div class="custom-column d-flex align-items-start flex-column">
          <div class="custom-heading font-semibold">Company</div>
          <router-link to="/about" class="custom-option"> About Us </router-link>
          <router-link to="/" class="custom-option"> Blogs </router-link>
        </div>
        <div class="custom-column d-flex align-items-start flex-column">
          <div class="custom-heading font-semibold">Owners</div>
          <a target="_blank" href="https://owners-dashboard.pages.dev/pages/listingpage" class="custom-option"> List your property </a>
          <a target="_blank" href="https://www.jadecaps.com/wall-of-love/" class="custom-option"> Wall of Love </a>
        </div>
        <div class="custom-column d-flex align-items-start flex-column">
          <div class="custom-heading font-semibold">Learn More</div>
          <router-link to="/" class="custom-option"> Privacy Policy </router-link>
          <router-link to="/" class="custom-option"> Terms {{"&"}} Conditions </router-link>
          <router-link to="/" class="custom-option"> Contact Us </router-link>
          <router-link to="/" class="custom-option"> FAQs </router-link>

        </div>
      </div>
      <div class="custom-height-less h-25">
        <hr />
        <div class="custom-footer-copy text-center mt-2">
          All Rights Reserved. Jadecaps ©2021.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.custom-footer {
  min-height: 55vh;
  height: 55vh;
  width: 100%;
  color: #fff;
  background-color: #303041;
  position: relative;
  display: flex;
  flex-direction: column;
  .custom-container {
    width: 80%;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
  }
}
.custom-column {
  padding-top: 2rem;
  .custom-heading {
    font-size: 1.2rem;
    margin-bottom: 2rem;
  }
  .custom-option {
    color: #fff;
    font-size: 1.2rem;
    opacity: 0.7;
    text-decoration: none;
    margin-bottom: 0.5rem;
  }
}
.custom-footer-copy {
  font-size: 1.2rem;
}

@media only screen and (max-width: 578px) {
  .custom-footer {
    height: auto;
    min-height: auto;
    .custom-container {
      width: 100%;
      
    }
  }  
  .custom-class-responsive{
    flex-direction: column !important;
    align-items: center;
    .custom-column{
      align-items: center !important;
    }
  }
}

</style>
