<template>
  <div class="w-100 position-relative">
    <div class="custom-container w-100 position-relative">
      <div
        class="
          custom-card
          color-white
          d-flex
          justify-content-between
          align-items-center
          flex-column
          bg-color-violet
        "
      >
        <div class="font-size-big-small font-bold mb-2">Try Hosting!</div>
        <div class="font-size-mid-small text-center mb-2">
          Earn extra income and unlock new <br />
          opportunities by sharing your space.
        </div>
        <a
          href="https://owners-dashboard.pages.dev/pages/registration"
          class="
            custom-button
            color-violet
            bg-color-white
            d-flex
            justify-content-center
            align-items-center
            font-size-mid-small
            mt-2
          "
        >
          Learn More
        </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.custom-container {
  width: 100%;
  height: 60vh;
  .custom-card {
    width: 80%;
    margin: 0 auto;
    height: 80%;
    border-radius: 2rem;
    padding: 4rem 0rem;
  }
  .custom-button {
    width: 15rem;
    height: 3.5rem;
    border-radius: 5px;
    text-decoration: none;
    box-shadow: 0px 5px 30px -3px rgba($color: #000000, $alpha: 0.13);
    transition: 0.3s ease;
    &:hover {
      transform: translateY(-2px) scale(1.02);
    }
  }
}
@media only screen and (max-width: 578px) {
  .custom-container {
    .custom-card {
      width: 95%;
    }
  }
}
</style>
