<template lang="">
  <div class="custom-card bg-color-white">
    <div class="top position-relative h-75 w-100">
      <img :src="cardImage" alt="No image available" />
    </div>
    <div class="bottom position-relative h-25 p-3">
      <div class="font-semibold font-size-mid-small">{{ cardHeader }}</div>
      <div class="font-size-small">{{ cardText }}</div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["cardImage", "cardHeader", "cardText"],
};
</script>
<style lang="scss" scoped>
.custom-card {
  width: 18rem;
  height: 22rem;
  background-color: white;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  box-shadow: 0px 10px 30px -3px rgba($color: #000000, $alpha: 0.13);
  z-index: 10;
  .top {
    transition: 0.3s ease;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 1s cubic-bezier(0.215, 0.61, 0.355, 1);
      &:hover {
        transform: scale(1.3);
      }
    }
  }
}
</style>
