<template>
<div>
  <div id="ip-slider">
    <input type="range" min="100" max="100000" step="100" v-model="value" @input="updateSlider" :style="{backgroundSize: backgroundSize}">
    <div class="data">Max Price: {{ value }}</div>
  </div>
  <div class="custom-apply" @click="useSetPriceRange">
      Apply
  </div>
</div>

</template>

<script>
export default {
data () {
		return {
			value: 100,
      backgroundSize: '0% 100%'
		}
	},
   methods: {
     updateSlider(e) {
            let clickedElement = e.target,
                min = clickedElement.min,
                max = clickedElement.max,
                val = clickedElement.value;

            this.backgroundSize = (val - min) * 100 / (max - min) + '% 100%';
     },
     useSetPriceRange(){
       this.setPriceRange(this.value);
     }
   },
   props: ['setPriceRange']
}
</script>

<style lang="scss">

#ip-slider {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 80%;
  transform: translate(-50%,-50%);
}
.data {
  text-align: center;
  margin-top: 24px;
}
/* Slider CSS */
input[type=range] {
    -webkit-appearance: none;
    display: block;
    width: 100%;
    margin: 16px 0;
    background: #600975;
    background-image: -webkit-gradient(linear, 20% 0%, 20% 100%, color-stop(0%, #1eb818), color-stop(100%, #1eb818));
    background-image: -webkit-linear-gradient(left, #1eb818 0%,#1eb818 100%);
    background-image: -moz-linear-gradient(left, #1eb818 0%, #1eb818 100%);
    background-image: -o-linear-gradient(to right, #1eb818 0%,#1eb818 100%);
    background-image: linear-gradient(to right, #1eb818 0%,#1eb818 100%);
    background-repeat: no-repeat;
}
input[type=range]:focus {
  outline: none;
}
input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  border-radius: 0px;
  border: none;
}
input[type=range]::-webkit-slider-thumb {
  box-shadow: none;
  border: 4px solid rgb(7, 194, 7);
  height: 24px;
  width: 24px;
  border-radius: 50%;
  background: #fff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -11px;
}
input[type=range]:focus::-webkit-slider-runnable-track {
  background: transparent;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  box-shadow: none;
  background: transparent;
  border-radius: 0px;
  border: none;
}
input[type=range]::-moz-range-thumb {
  box-shadow: none;
  border: 4px solid #ADD8E6;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  background: #ffffff;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 4px;
  cursor: pointer;
  background: transparent;
  border-color: transparent;
  color: transparent;
}
input[type=range]::-ms-fill-lower {
  background: transparent;
  border: none;
  border-radius: 0px;
  box-shadow: none;
}
input[type=range]::-ms-fill-upper {
  background: transparent;
  border: none;
  border-radius: 0px;
  box-shadow: none;
}
input[type=range]::-ms-thumb {
  box-shadow: none;
  border: 4px solid #ADD8E6;
  height: 16px;
  width: 16px;
  border-radius: 2px;
  background: #ffffff;
  cursor: pointer;
  height: 4px;
}
input[type=range]:focus::-ms-fill-lower {
  background: transparent;
}
input[type=range]:focus::-ms-fill-upper {
  background: transparent;
}
.custom-apply{
    color: #1eb818;
    background-color: rgba(29, 184, 24, 0.07);
    cursor: pointer;
    border: 1px solid #1eb818;
    border-radius: 2rem;
    padding: 0.5rem 1rem;
    font-weight: 500;
    transition: 0.3s ease;
    max-width: 6rem;
    text-align: center;
    &:hover{
        background-color: #1eb818;
        color: #fff;
    }
    
}
/* End Range Slider */

</style>