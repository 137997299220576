<template>
  <div class="w-100">
    <nav
      class="
        d-none d-sm-flex
        justify-content-between
        align-items-center
        px-4
        py-2
        shadow
      "
      style="height: 6rem"
    >
      <LogOutPop :changeIsSignedIn="changeIsSignedIn"/>
      <div>
        <a
          href="/"
          class="d-flex align-items-center"
          style="text-decoration: none"
        >
          <img :src="JDLogo" style="height:7rem;width:auto;" alt="Jadecapes">
        </a>
      </div>

      <div class="custom-container d-flex justify-content-between">
        <div
          class="
            custom-search
            d-flex
            justify-content-center
            align-items-center
            px-2
            border-right
          "
        >
          <div class="w-100 d-flex align-items-end" style="box-shadow:0px 5px 20px -3px rgba(1,1,1,0.1)">
            <!-- <div>
              <img :src="Search" alt="" style="position:absolute; top:50%;left:0; transform:translateX(-50%)" />
            </div> -->
            <b-form-select 
            style="width:30%; height:3rem; border: none; background: #FCF9FF; border-radius:4px 0px 0px 4px; border:1px solid #FCF9FF; outline: none;" 
            v-model="selectedCity"
            value-field="value"
            text-field="text" 
            :options="cities"></b-form-select>

            <input
              type="text"
              placeholder="Search your locality....."
              id="navlocation"
              style="height:3rem; background:#FCF9FF; margin-left:0px; padding-left:10px; border: 1px solid #FCF9FF; border-radius:0px 4px 4px 0px;"
              @keypress="searchByCity($event)"
            />
          </div>
        </div>
        <div
          class="
            custom-details
            d-flex
            justify-content-end
            align-items-center
            px-2
          "
        >
          <div class="mx-4">
            <a
              href="/"
              class="
                d-flex
                justify-content-between
                align-items-center
                custom-hover-item
              "
            >
              Home
            </a>
          </div>
          <div class="mx-4">
            <a
              href="/property"
              class="
                d-flex
                justify-content-between
                align-items-center
                custom-hover-item
              "
            >
              Find Homes
            </a>
          </div>
          <!-- <div>
            <a
              href="https://owners-dashboard.pages.dev/pages/listingpage"
              target="_blank"
              class="custom-router-link d-flex justify-content-between align-items-center"
            >
              List Your Property
            </a>
          </div> -->
          <div class="mx-4" v-if="!isSignedIn">
            <a
              href="/login"
              class="
                d-flex
                justify-content-between
                align-items-center
                custom-hover-item
              "
            >
              Sign In
            </a>
          </div>
          <div class="mx-4" v-if="!isSignedIn">
            <a
              href="/register"
              class="
                d-flex
                justify-content-between
                align-items-center
                custom-hover-item
              "
            >
              Sign Up
            </a>
          </div>

          <div>
          <b-dropdown id="dropdown-special" right text="Dropdown Button" class="m-md-2" variant="none" v-if="isSignedIn">
            <template #button-content>
              <span class="custom-hover-item">
                Account
              </span>
            </template>
            <b-dropdown-item @click="$router.push('/reservations')">              
              <router-link class="w-100" to="/reservations" style="text-decoration:none;font-weight:500;cursor:pointer;text-align:center;color:#212529;">Reservations</router-link>
            </b-dropdown-item>
            <b-dropdown-item @click="$router.push('/profile')">
              <router-link class="w-100" to="/profile" style="text-decoration:none;font-weight:500;cursor:pointer;text-align:center;color:#212529;">Profile</router-link>
            </b-dropdown-item>
            <b-dropdown-item>
              <div class="position-relative" v-if="isSignedIn" onclick="document.getElementById('id01').style.display='block'">
                <div 
                style="font-weight:500;cursor:pointer;"
                  >
                  Logout
                </div>
              </div>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        </div>
      </div>
    </nav>
  </div>
</template>
<script>
import PlusIcon from "@/assets/Plus.png";
import Logout from "@/assets/log_out.png";
import Bell from "@/assets/bell.png";
import Search from "@/assets/loupe.png";
import LogOutPop from '../LogOutPop/LogOutPop.vue';
import JDLogo from '@/assets/JadeCaps_logo_F-01.png';
// import { getUserDetails } from "@/utils/api.js";
export default {
  components: { LogOutPop },
  data() {
    return {
      PlusIcon,
      Logout,
      Bell,
      Search,
      number: 9,
      name: "Mahipal Balan",
      userPicture:
        "https://www.adobe.com/content/dam/cc/us/en/creativecloud/photography/discover/portrait-photography/CODERED_B1_portrait_photography-P4a_438x447.jpg.img.jpg",
      isSignedIn: false,
      JDLogo,
      cities: [
        { text: "Bangalore", value: "Bangalore" },
        { text: "Hosur", value: "Hosur" },
        { text: "Chikmagalur", value: "Chikmagalur" },
        { text: "Lonavala", value: "Lonavala" },
        { text: "Goa", value: "Goa" },
        { text: "Bikaner", value: "Bikaner" },
        { text: "Jodhpur", value: "Jodhpur" },
        { text: "Delhi", value: "Delhi" },
      ],
      selectedCity: "Bangalore",
    };
  },

  methods: {
    logOut(e) {
      e.preventDefault();
      document.getElementById('id01').style.display='none';
      localStorage.removeItem("app_access_token");
      localStorage.removeItem("app_refresh_token");
      this.$router.push("/");
    },
    searchByCity(e) {
      if (e.keyCode === 13) {
        this.$router.push(`/property?city=${e.target.value.split(",")[0]}`);
        this.$router.go();
      }
      console.log(e.target.value);
    },
    changeIsSignedIn(){
      return;
    },
    citySearchStuff() {
      var cityBounds = new google.maps.LatLngBounds(
      new google.maps.LatLng(12.864162, 77.438610),
      new google.maps.LatLng(13.139807, 77.711895));
      new google.maps.places.Autocomplete(
        document.getElementById("navlocation"),
        {
          componentRestrictions: { country: ["in"] },
          // types: ["(cities)"],
          strictBounds: true,
          // fields: ["formatted_address", "geometry", "name"],
          bounds: cityBounds,
        }
      );
    }
  },

  mounted() {
    // this.useGetUserDetails();
    if (localStorage.getItem("app_access_token")) {
      this.isSignedIn = true;
    }
    this.citySearchStuff();
    
  },
};
</script>
<style lang="scss" scoped>
nav {
  display: flex;
  .custom-container {
    width: 80%;
  }
  .custom-details {
    width: 40%;
    img {
      height: 1.2rem;
      width: auto;
    }
    .custom-notification {
      height: 1.2rem;
      width: 1.2rem;
      border-radius: 50%;
      top: -0.3rem;
      right: -0.5rem;
    }
    .custom-image {
      img {
        height: 2.2rem;
        width: 2.2rem;
        object-fit: cover;
        border-radius: 2rem;
      }
    }
  }
  .custom-search {
    width: 60%;
    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid rgba(1, 1, 1, 0.2);
      outline: none;
      margin-left: 0.4rem;
      &::placeholder {
        opacity: 0.3;
        font-weight: 600;
        color: #111;
      }
    }
    img {
      height: 1.2rem;
      width: 1.2rem;
      opacity: 0.3;
    }
  }
  .custom-router-link {
    background-color: #3c3ce1;
    padding: 10px 20px;
    border-radius: 50px;
    text-decoration: none;
    color: #fff;
  }
}
.custom-hover-item {
  color: #333;
  text-decoration: none;
  font-weight: 500;
  transition: 0.3s ease;
  &:hover {
    color: #3c3ce1;
    text-decoration: underline;
  }
}
.special-square {
  height: 2rem;
  width: 2rem;
  background-color: #3c3ce1;
}
#dropdown-special{
  background-color: transparent;
  color: #333;
}

</style>
