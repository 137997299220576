<template>
  <div>
    <NavbarCommon />
    <NavbarSmall />
    <div class="w-100 d-flex flex-column" v-if="!loginModal">
      <div class="custom-container">
        <div class="w-100">
          <div style="font-size: 1.4rem; font-weight: 500">
            {{ property.property_name }}
          </div>
          <div>
            <div class="d-flex align-items-end mb-4">
              <span style="color: blueviolet; font-size: 1.1rem">
                &#9733;
              </span>
              <span
                ><small>
                  {{ calculateAvgRatings(property.reviews) }} (<span
                    style="opacity: 0.7"
                    >{{ property.reviews.total_reviews
                    }}{{
                      property.reviews.total_reviews != 1
                        ? " reviews"
                        : " review"
                    }}</span
                  >).
                </small></span
              >
              <small>
                <span>
                  {{ property.property_address.city }},
                  {{ property.property_address.state }}
                  {{ property.property_address.country }}
                </span>
              </small>
            </div>
          </div>
        </div>
        <div
          class="custom-image-container"
          v-if="property.images.length == 5"
          @click="hidePropertyShowImage"
        >
          <div class="big-image">
            <img :src="property.images[0].image_url" alt="No Image available" />
          </div>
          <div class="small-image">
            <img :src="property.images[1].image_url" alt="No Image available" />
          </div>
          <div class="small-image2">
            <img :src="property.images[2].image_url" alt="No Image available" />
          </div>
          <div class="small-image3">
            <img :src="property.images[3].image_url" alt="No Image available" />
          </div>
          <div class="small-image4">
            <img :src="property.images[4].image_url" alt="No Image available" />
          </div>
        </div>
        <div
          class="custom-image-container"
          v-if="property.images.length > 5"
          @click="hidePropertyShowImage"
        >
          <div class="big-image">
            <img :src="property.images[0].image_url" alt="No Image available" />
          </div>
          <div class="small-image">
            <img :src="property.images[1].image_url" alt="No Image available" />
          </div>
          <div class="small-image2">
            <img :src="property.images[2].image_url" alt="No Image available" />
          </div>
          <div class="small-image3">
            <img :src="property.images[3].image_url" alt="No Image available" />
          </div>
          <div class="small-image4">
            <img :src="property.images[4].image_url" alt="No Image available" />
          </div>
          <div
            class="
              small-image4
              d-flex
              justify-content-center
              align-items-center
            "
            style="background-color: #eee; cursor: pointer"
            v-if="property.images.length > 4"
          >
            <div style="font-size: 1.5rem">
              +{{ property.images.length - 4 }} Photos >
            </div>
          </div>
        </div>
        <div
          class="custom-image-container"
          v-if="property.images.length <= 4"
          @click="hidePropertyShowImage"
        >
          <div class="big-image">
            <img :src="property.images[0].image_url" alt="No Image available" />
          </div>
          <div
            :class="generateImageClass(index)"
            v-for="(image, index) in property.images.slice(
              1,
              property.images.length
            )"
            :key="index"
          >
            <img :src="image.image_url" alt="No Image available" />
          </div>
        </div>

        <CarouselModal :property="property" />
        <div class="custom-details-container d-flex justify-content-center">
          <div class="custom-details-left">
            <div style="font-size: 1.3rem; font-weight: 500">Details</div>
            <div class="custom-details-content d-flex justify-content-start">
              <div
                class="custom-info-card p-3 rounded border-2 d-flex flex-column"
              >
                <div>
                  <img
                    src="../assets/humans.png"
                    style="height: 1rem; width: auto"
                    alt="No Image available"
                  />
                </div>
                <div style="opacity: 0.7; margin: auto 0">
                  {{ property.property_details.beds }}
                  <span>{{
                    property.property_details.beds == 1 ? "Bed" : "Beds"
                  }}</span>
                </div>
              </div>
              <div
                class="custom-info-card p-3 rounded border-2 d-flex flex-column"
              >
                <div>
                  <img
                    src="../assets/humans.png"
                    style="height: 1rem; width: auto"
                    alt="No Image available"
                  />
                </div>
                <div style="opacity: 0.7; margin: auto 0">
                  {{ property.property_details.bedrooms }}
                  <span>{{
                    property.property_details.bedrooms == 1
                      ? "Bedroom"
                      : "Bedrooms"
                  }}</span>
                </div>
              </div>
              <div
                class="custom-info-card p-3 rounded border-2 d-flex flex-column"
              >
                <div>
                  <img
                    src="../assets/humans.png"
                    style="height: 1rem; width: auto"
                    alt="No Image available"
                  />
                </div>
                <div style="opacity: 0.7; margin: auto 0">
                  {{ property.property_details.baths }}
                  <span>{{
                    property.property_details.baths == 1 ? "Bath" : "Baths"
                  }}</span>
                </div>
              </div>
              <div
                class="custom-info-card p-3 rounded border-2 d-flex flex-column"
              >
                <div>
                  <img
                    src="../assets/humans.png"
                    style="height: 1rem; width: auto"
                    alt="No Image available"
                  />
                </div>
                <div style="opacity: 0.7; margin: auto 0">
                  {{ property.property_details.balcony }}
                  <span>{{
                    property.property_details.balcony == 1
                      ? "Balcony"
                      : "Balconys"
                  }}</span>
                </div>
              </div>
            </div>
            <div class="mt-4" style="font-size: 1.3rem; font-weight: 500">
              About this space:
            </div>
            <div class="custom-description w-75 mt-4">
              {{
                property.description ? property.description : "No Description"
              }}
            </div>
            <div class="mt-4" style="font-size: 1.3rem; font-weight: 500">
              Amenities
            </div>
            <div
              class="
                custom-ameneties-container
                d-flex
                justify-contnet-start
                flex-wrap
                mt-4
              "
              v-if="property.amenities.length > 0"
            >
              <div
                class="
                  custom-amenity
                  d-flex
                  justify-content-start
                  align-items-center
                  p-2
                  mb-2
                "
                v-for="(item, index) in property.amenities"
                :key="index"
              >
                <img
                  :src="item.icon"
                  alt="No Image available"
                  style="height: 1.2rem; width: auto"
                  v-if="item.icon"
                />
                <span style="opacity: 0.5; margin-left: 1rem"
                  ><small>{{ item.name }}</small></span
                >
              </div>
            </div>
          </div>
          <div class="custom-details-right">
            <div class="mt-4" style="font-size: 1.3rem; font-weight: 500">
              Property Location
            </div>
            <div class="mt-3">
              {{ property.property_address.city }},
              {{ property.property_address.state }}
              {{ property.property_address.country }}
            </div>
            <div
              class="custom-map-container mt-4 mb-4 w-100"
              style="font-size: 1.3rem; font-weight: 500"
            >
              <IndividualMap
                :latitude1="property.property_address.latitude"
                :longitude1="property.property_address.longitude"
              />
            </div>
          </div>
        </div>
        <div
          class="mt-4"
          style="font-size: 1.3rem; font-weight: 500"
          ref="pricing"
        >
          Pricing
        </div>
        <div class="book-now-container">
          <div class="custom-book-header">Holiday Rental</div>
          <div class="custom-shadow-card">
            <!-- div container -->
            <div class="pricing-header">
              <!-- div header -->
              <div class="pricing-column-left">Booking Details</div>
              <div class="pricing-column-middle">Amenities</div>
              <div class="pricing-column-guest">No. of Guest</div>
              <div class="pricing-column-right">Pricing Details</div>
            </div>
            <div class="pricing-body">
              <!-- div body -->
              <ul class="pricing-column-left">
                <div class="responsive-header">Booking Details</div>

                <li
                  v-for="(value, key) in property.property_details"
                  :key="key"
                >
                  {{ value }} {{ key }}
                </li>
                <li>
                  <span>Non-refundable</span> | On cancellation zero refund
                  applicable.
                </li>
              </ul>
              <ul class="pricing-column-middle">
                <div class="responsive-header">Amenities</div>

                <li v-for="(value, key) in property.amenities" :key="key">
                  {{ value.name }}
                </li>
              </ul>
              <div class="pricing-column-guest">
                <div class="responsive-header">No. of Guest</div>

                <div class="counter-btn" @click="changeGuestCount(0)">-</div>
                <input type="text" v-model="guestCount" readonly />
                <div class="counter-btn" @click="changeGuestCount(1)">+</div>
              </div>
              <div class="pricing-column-right">
                <div class="responsive-header">Pricing Details</div>

                <div>
                  <div>
                    {{ amountPerNight ? amountPerNight * guestCount : "-" }}
                  </div>
                  <span>/- per night</span>
                </div>
                <div class="d-flex align-items-center">
                  <span style="font-weight:600; font-size:1.2rem;" >Total Amount:&nbsp;{{ amountFinal ? amountFinal * guestCount : "-" }}/- </span>
                  <div>
                    
                  </div>
                </div>
              </div>
            </div>
            <div class="pricing-footer">
              <!-- div foot -->
              <div class="checkin-container">
                <div>Check In</div>
                <input
                  type="date"
                  placeholder="From"
                  v-model="date.checkIn"
                  v-on:change="calculatePrice"
                  :min="date.checkInMin"
                />
                <div>-></div>
                <div>Check Out</div>
                <input
                  type="date"
                  placeholder="To"
                  v-model="date.checkOutShort"
                  v-on:change="calculatePrice"
                  :min="date.checkIn"
                  :max="date.checkOutShortMax"
                />
              </div>
              <div class="book-now-btn" @click="bookNow(1)">Book Now</div>
            </div>
          </div>
        </div>
        <div class="book-now-container">
          <div class="custom-book-header">Urban Rental</div>
          <div class="custom-shadow-card">
            <!-- div container -->
            <div class="pricing-header">
              <!-- div header -->
              <div class="pricing-column-left">Booking Details</div>
              <div class="pricing-column-middle">Amenities</div>
              <div class="pricing-column-guest">No. of Guest</div>
              <div class="pricing-column-right">Pricing Details</div>
            </div>
            <div class="pricing-body">
              <!-- div body -->
              <ul class="pricing-column-left">
                <div class="responsive-header">Booking Details</div>
                <li
                  v-for="(value, key) in property.property_details"
                  :key="key"
                >
                  {{ value }} {{ key }}
                </li>
                <li>
                  <span>Non-refundable</span> | On cancellation zero refund
                  applicable.
                </li>
              </ul>
              <ul class="pricing-column-middle">
                <div class="responsive-header">Amenities</div>

                <li v-for="(value, key) in property.amenities" :key="key">
                  {{ value.name }}
                </li>
              </ul>
              <div class="pricing-column-guest">
                <div class="responsive-header">No. of Guest</div>

                <div class="counter-btn" @click="changeGuestCount(0)">-</div>
                <input type="text" v-model="guestCount" readonly />
                <div class="counter-btn" @click="changeGuestCount(1)">+</div>
              </div>
              <div class="pricing-column-right">
                <div class="responsive-header">Pricing Details</div>

                <div>
                  <div>
                    {{ property.property_price.long_stay.price * guestCount }}
                  </div>
                  <span> /- per month</span>
                </div>
                <hr />
                <div class="d-flex align-items-center">
                  <span style="font-weight:600; font-size:1.2rem;">Total Amount:&nbsp;                     {{
                      property.property_price.long_stay.price *
                      guestCount *
                      (this.daysLong / 30).toFixed(2)
                    }}/-</span>
                  <div>

                  </div>
                </div>
              </div>
            </div>
            <div class="pricing-footer">
              <!-- div foot -->
              <div class="checkin-container">
                <div>Check In</div>
                <input
                  type="date"
                  placeholder="From"
                  v-model="date.checkIn"
                  v-on:change="calculatePrice"
                  :min="date.checkInMin"
                />
                <div>-></div>
                <div>Check Out</div>
                <input
                  type="date"
                  placeholder="To"
                  v-model="date.checkOutLong"
                  v-on:change="calculatePrice"
                  :min="date.checkOutLongMin"
                />
              </div>
              <div class="book-now-btn" @click="bookNow(0)">Book Now</div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 mt-4">
        <Footer />
      </div>
    </div>
    <div v-if="loginModal">
      <LoginModal :setLoginModal="setLoginModal" />
    </div>
    <div v-if="isLoading">
      <MainLoader />
    </div>
  </div>
</template>
<script>
import NavbarCommon from "@/components/NavbarCommon/NavbarCommon.vue";
import NavbarSmall from "@/components/NavbarSmall/NavbarSmall.vue";
import IndividualMap from "../components/IndividualMap/IndividualMap.vue";
import Footer from "@/components/Footer/Footer.vue";
import { getPropertyById, postBookingSummary } from "@/utils/apis.js";
import { Carousel, Slide } from "vue-carousel";
import CarouselModal from "../components/CarouselModal/CarouselModal.vue";
import MainLoader from "../components/MainLoader/MainLoader.vue";
import LoginModal from "@/components/LoginModal/LoginModal.vue";


// import BookNowCard from '../components/Property/BookNowCard.vue';
export default {
  data() {
    return {
      loginModal: false,
      default: {},
      date: {
        date: new Date(),
        checkIn: new Date().toISOString().slice(0, 10),
        checkOutShort: new Date(new Date().setDate(new Date().getDate() + 10))
          .toISOString()
          .slice(0, 10),
        checkOutLong: new Date(new Date().setDate(new Date().getDate() + 30))
          .toISOString()
          .slice(0, 10),
        checkInMin: new Date().toISOString().slice(0, 10),
        checkOutShortMax: new Date(
          new Date().setDate(new Date().getDate() + 29)
        )
          .toISOString()
          .slice(0, 10),
        checkOutLongMin: new Date(new Date().setDate(new Date().getDate() + 30))
          .toISOString()
          .slice(0, 10),
      },
      guestCount: 1,
      amountPerNight: 0,
      amountFinal: 0,
      daysShort: 0,
      daysLong: 0,
      weekEndCount: 0,
      errors: {
        dateErrors: {
          long: null,
          short: null,
        },
      },
      property: {
        property_id: 1,
        property_name: "Mahipal's house",
        property_address: {
          country: "India",
          city: "Bangalore",
          state: "Karnataka",
          zip: "400128",
          latitude: "22.581934",
          longitude: "88.423429",
        },
        images: [
          {
            image_id: 1,
            image_url:
              "https://images.pexels.com/photos/1571460/pexels-photo-1571460.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
          },
          {
            image_id: 2,
            image_url:
              "https://images.pexels.com/photos/2029670/pexels-photo-2029670.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
          },

          {
            image_id: 3,
            image_url:
              "https://images.pexels.com/photos/5998051/pexels-photo-5998051.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260",
          },
          {
            image_id: 4,
            image_url:
              "https://images.pexels.com/photos/4192783/pexels-photo-4192783.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
          },
          {
            image_id: 5,
            image_url:
              "https://images.pexels.com/photos/6394514/pexels-photo-6394514.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
          },
          {
            image_id: 6,
            image_url:
              "https://images.pexels.com/photos/6394514/pexels-photo-6394514.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940",
          },
        ],
        reviews: {
          total_reviews: 3,
          review_rating: 4.5,
          review_list: [
            {
              review_id: 1,
              review_title: "Review Title",
              review_description: "Bohut accha hoi",
              review_rating: 4,
              review_date: "20/12/2020",
              review_user: "Mahipal",
            },
            {
              review_id: 3,
              review_title: "Review Title",
              review_description: "Bohut accha hoi",
              review_rating: 4,
              review_date: "20/12/2020",
              review_user: "Mahipal",
            },
            {
              review_id: 3,
              review_title: "Review Title",
              review_description: "Bohut accha hoi",
              review_rating: 4,
              review_date: "20/12/2020",
              review_user: "Mahipal",
            },
          ],
        },
        amenities: [
          {
            amenitiy_id: 1,
            amenitiy_name: "Swimming Pool",
            amenitiy_icon:
              "https://img.icons8.com/color/48/000000/swimming-pool.png",
          },
          {
            amenitiy_id: 2,
            amenitiy_name: "Indoor Fireplace",
            amenitiy_icon:
              "https://img.icons8.com/color/48/000000/fireplace.png",
          },
          {
            amenitiy_id: 3,
            amenitiy_name: "Gym",
            amenitiy_icon: "https://img.icons8.com/color/48/000000/gym.png",
          },
          {
            amenitiy_id: 4,
            amenitiy_name: "Security",
            amenitiy_icon: "https://img.icons8.com/color/48/000000/lock.png",
          },
          {
            amenitiy_id: 5,
            amenitiy_name: "Swimming Pool",
            amenitiy_icon:
              "https://img.icons8.com/color/48/000000/swimming-pool.png",
          },
          {
            amenitiy_id: 6,
            amenitiy_name: "Indoor Fireplace",
            amenitiy_icon:
              "https://img.icons8.com/color/48/000000/fireplace.png",
          },
          {
            amenitiy_id: 7,
            amenitiy_name: "Gym",
            amenitiy_icon: "https://img.icons8.com/color/48/000000/gym.png",
          },
          {
            amenitiy_id: 8,
            amenitiy_name: "Security",
            amenitiy_icon: "https://img.icons8.com/color/48/000000/lock.png",
          },
          {
            amenitiy_id: 9,
            amenitiy_name: "Swimming Pool",
            amenitiy_icon:
              "https://img.icons8.com/color/48/000000/swimming-pool.png",
          },
          {
            amenitiy_id: 10,
            amenitiy_name: "Indoor Fireplace",
            amenitiy_icon:
              "https://img.icons8.com/color/48/000000/fireplace.png",
          },
          {
            amenitiy_id: 11,
            amenitiy_name: "Gym",
            amenitiy_icon: "https://img.icons8.com/color/48/000000/gym.png",
          },
          {
            amenitiy_id: 12,
            amenitiy_name: "Security",
            amenitiy_icon: "https://img.icons8.com/color/48/000000/lock.png",
          },
        ],
        property_details: {
          beds: 3,
          bedrooms: 2,
          baths: 2,
          balcony: 1,
        },
        property_price: {
          price: "4322",
          currency: "USD",
          duration: "monthly",
        },
        description:
          "Acche log chiye bas aur koy khas iccha nahi ahi apun ko, aur time pe paisa zarror dena, warna bhai ko phone lagaunga",
      },
      isLoading: true,
      latitude: 0,
      longitude: 0,
    };
  },
  methods: {
    goto(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;

      window.scrollTo(0, top);
    },
    hidePropertyShowImage() {
      document.getElementById('id02').style.display='block';
      document.querySelector('body').style.overflow = 'hidden';
    },
    calculatePrice() {
      console.log("from");
      console.log(new Date(this.date.checkIn).getDay());
      console.log("to");
      console.log(this.date.checkOutShort);

      this.date.checkOutShortMax = new Date(
        new Date(this.date.checkIn).setDate(
          new Date(this.date.checkIn).getDate() + 29
        )
      )
        .toISOString()
        .slice(0, 10);
      this.date.checkOutLongMin = new Date(
        new Date(this.date.checkIn).setDate(
          new Date(this.date.checkIn).getDate() + 30
        )
      )
        .toISOString()
        .slice(0, 10);
      console.log(`max is ${this.date.checkOutShortMax}`);

      let basePrice = 0;
      let weekEnd =
        new Date(this.date.checkIn).getDay() == 0 ||
        new Date(this.date.checkIn).getDay() == 6
          ? true
          : false;

      basePrice = weekEnd
        ? this.property.property_price.short_stay.weekend_base_price
        : this.property.property_price.short_stay.week_day_base_price;
      this.daysShort =
        (new Date(this.date.checkOutShort) - new Date(this.date.checkIn)) /
        86400000;
      this.daysLong =
        (new Date(this.date.checkOutLong) - new Date(this.date.checkIn)) /
        86400000;
      this.weekEndCount = this.countWeekendDays(
        new Date(this.date.checkIn),
        new Date(this.date.checkOutShort)
      );
      console.log(`weekends count: ${this.weekEndCount}`);
      this.weekDaysCount = this.daysShort - this.weekEndCount;

      this.amountPerNight = basePrice;
      this.amountFinal =
        this.weekDaysCount *
          this.property.property_price.short_stay.week_day_base_price +
        this.weekEndCount *
          this.property.property_price.short_stay.weekend_base_price;

      console.log("amount is");
      console.log(this.amountFinal);
    },
    changeGuestCount(x) {
      this.guestCount = x
        ? this.guestCount + 1
        : this.guestCount - 1
        ? this.guestCount - 1
        : this.guestCount;

      this.goto("pricing");
    },
    countWeekendDays(d0, d1) {
      var ndays =
        1 + Math.round((d1.getTime() - d0.getTime()) / (24 * 3600 * 1000));
      var nsaturdays = Math.floor((d0.getDay() + ndays) / 7);
      return 2 * nsaturdays + (d0.getDay() == 0) - (d1.getDay() == 6);
    },
    async bookNow(stay) {
      if(localStorage.getItem("app_access_token")){
      this.days = stay ? this.daysShort : this.daysLong;
      console.log(`its ${this.days}`);
      
      console.log("**&&&", this.date.checkOutShort);
      const apiBody = {
        house_id: this.property.property_id,
        check_in:
          this.date.checkIn.split("-")[2] +
          "/" +
          this.date.checkIn.split("-")[1] +
          "/" +
          this.date.checkIn.split("-")[0],
        check_out: stay
          ? this.date.checkOutShort.split("-")[2] +
            "/" +
            this.date.checkOutShort.split("-")[1] +
            "/" +
            this.date.checkOutShort.split("-")[0]
          : this.date.checkOutLong.split("-")[2] +
            "/" +
            this.date.checkOutLong.split("-")[1] +
            "/" +
            this.date.checkOutLong.split("-")[0],
        no_of_week_days: (stay)?this.weekDaysCount:null,
        no_of_weekend_days: (stay)?this.weekEndCount:null,
        no_of_days: this.days,
        booking_type: (stay)?"daily":"monthly",
        no_of_guests: this.guestCount,
      };
      const res = await postBookingSummary(apiBody);
      this.$router.push({
        name: "Booking Summary",
        query: {
          date: this.date,
          property: this.property,
          stay: stay,
          amountFinal: stay
            ? this.amountFinal * this.guestCount
            : this.property.property_price.long_stay.price *
              (this.days / 30) *
              this.guestCount,
          guestCount: this.guestCount,
          days: this.days,
          houseId: this.property.property_id,
        },
      });
      console.log("**&&", res);
      } else {
          this.setLoginModal();
        }
    },
    setLoginModal() {
      this.loginModal = !this.loginModal;
    },
    setCoordinates(lat, lng) {
      this.latitude = lat;
      this.longitude = lng;
    },
    async useGetPropertyById() {
      try {
        const res = await getPropertyById(this.$route.params.id);
        console.log("&&^%$");
        console.log(res);
        this.property = res.property;
        this.isLoading = false;
        // console.log("hi"+new Date(this.date.checkOutShort))
        this.calculatePrice();
      } catch (error) {
        this.$toasted.error("Couldn't find the property", {
          position: "top-center",
          action: {
            text: "Cancel",
            onClick: (e, toastObject) => {
              toastObject.goAway(0);
              this.$router.go(-1);
            },
          },
        });
      }
    },
    calculateAvgRatings(reviews) {
      const totalReviews = reviews.length;
      var totalScore = 0;
      reviews.map((review) => {
        totalScore += review.review_rating;
      });
      return reviews.length > 0 ? (totalScore / totalReviews).toFixed(1) : 0;
    },
    generateImageClass(index) {
      return `small-image${index == 0 ? "" : index + 1}`;
    },
    selfThis() {
      let self = this;
      return self;
    },
    getBusinessDatesCount(sd, ed) {
      var startDate = new Date(sd);
      var endDate = new Date(ed);
      let count = 0;
      const curDate = new Date(startDate.getTime());
      while (curDate <= endDate) {
        const dayOfWeek = curDate.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) count++;
        curDate.setDate(curDate.getDate() + 1);
      }
      return count;
    },
  },
  components: {
    NavbarCommon,
    IndividualMap,
    Footer,
    Carousel,
    Slide,
    CarouselModal,
    MainLoader,
    NavbarSmall,
    LoginModal
  },
  mounted() {
    this.useGetPropertyById();
    if (sessionStorage.getItem("routeChk") == "1") {
      let x = this.selfThis();
      setTimeout(function () {
        x.goto("pricing");
        sessionStorage.setItem("routeChk", 0);
      }, 200);
    }
  },
};
</script>
<style lang="scss" scoped>
.custom-container {
  width: 90%;
  margin: 0 auto;
  padding: 2rem 0;
}
.VueCarousel-slide {
  display: flex;
  justify-content: center;
}
.VueCarousel-inner {
  height: 100%;
}
.custom-image-container {
  display: grid;
  grid-template-columns: 1.8fr 0.6fr 0.6fr;
  grid-template-rows: 1fr 1fr;
  grid-auto-columns: 1fr;
  gap: 10px 10px;
  grid-auto-flow: row;
  max-height: 65vh;
  grid-template-areas:
    "big-image small-image small-image2"
    "big-image small-image3 small-image4";

  .big-image {
    grid-area: big-image;
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: 1s cubic-bezier(0.215, 0.61, 0.355, 1);
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .small-image {
    grid-area: small-image;
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: 1s cubic-bezier(0.215, 0.61, 0.355, 1);
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .small-image2 {
    grid-area: small-image2;
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: 1s cubic-bezier(0.215, 0.61, 0.355, 1);
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .small-image3 {
    grid-area: small-image3;
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: 1s cubic-bezier(0.215, 0.61, 0.355, 1);
      &:hover {
        transform: scale(1.1);
      }
    }
  }

  .small-image4 {
    grid-area: small-image4;
    position: relative;
    overflow: hidden;
    border-radius: 10px;

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      transition: 1s cubic-bezier(0.215, 0.61, 0.355, 1);
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
.book-now-container {
  border-radius: 1rem;
  box-shadow: -1px 3px 28px -8px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: -1px 3px 28px -8px rgba(0, 0, 0, 0.12);
  -moz-box-shadow: -1px 3px 28px -8px rgba(0, 0, 0, 0.12);
  margin: 3rem 0;
  .custom-book-header {
    border-radius: 1rem 1rem 0 0;
    padding: 1.2rem 3rem;
    font-size: 1.6rem;
    font-weight: 500;
  }
}
.custom-shadow-card {
  border-radius: 0 0 1rem 1rem;

  padding: 0.3rem 1rem;
  width: 100%;
  border-top: #8080802b 1px solid;
  .pricing-header {
    display: flex;
    background-color: #adacb726;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
    div {
      padding-left: 0.4rem;
    }
    .pricing-column-left {
      width: 30%;
    }
    .pricing-column-middle {
      width: 30%;
    }
    .pricing-column-guest {
      width: 20%;
    }
    .pricing-column-right {
      width: 20%;
    }
  }
  .pricing-body {
    display: flex;
    padding: 1rem;
    padding-top: 1.8rem;
    // ul{
    //   // padding: 1rem;
    // }
    .pricing-column-left {
      width: 30%;
    }
    .pricing-column-middle {
      width: 30%;
    }
    .pricing-column-guest {
      width: 20%;

      display: flex;
      // justify-content: center;
      align-items: center;
      input {
        width: 3rem;
        margin: 0.5rem;
        border-radius: 0.3rem;
        border: 1px solid grey;
        text-align: center;
      }
    }
    .pricing-column-right {
      width: 20%;
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-direction: column;
      div {
        display: flex;
      }
    }
  }
  .counter-btn {
    background-color: blue;
    border-radius: 0.5rem;
    width: 2rem;
    text-align: center;
    color: white;
    cursor: pointer;
  }
  .pricing-footer {
    padding: 1rem;
    padding-right: 0;
    display: flex;
    .checkin-container {
      display: flex;
      justify-content: space-around;
      padding: 0.5rem;
      padding-left: 0.4rem;
      margin-right: 1rem;
      border: 1px grey solid;
      border-radius: 0.3rem;
      width: 80%;
    }
    .book-now-btn {
      width: 20%;
      background-color: blue;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 0.3rem;
      cursor: pointer;
    }
  }
}

.custom-details-container {
  width: 100%;
  position: relative;
  padding: 2rem 0;
  .custom-details-left {
    width: 60%;
    height: 100%;
    padding: 1rem 0;
    .custom-details-content {
      margin-top: 1rem;
      flex-wrap: wrap;
      .custom-info-card {
        height: 6rem;
        border: 1px solid #b8b6b6;
        width: 9rem;
        margin-right: 1rem;
      }
    }
    .custom-ameneties-container {
      .custom-amenity {
        height: 3rem;
        border: 1px solid #b8b6b6;
        width: 13rem;
        border-radius: 5px;
        margin-right: 2rem;
      }
    }
  }
  .custom-details-right {
    width: 40%;
    height: 100%;
    padding: 1rem 0;
  }
}

.custom-map-container {
  height: 60vh;
  border-radius: 10px;
}

@media only screen and (max-width: 576px) {
  .pricing-header{
    display: none !important;
  }
  .pricing-body, .pricing-footer, .checkin-container{
    width: unset !important;
    flex-direction: column;
    .pricing-column-guest, .pricing-column-left, .pricing-column-middle, .pricing-column-right{
      width: unset !important;
    }
    .pricing-column-guest{
      justify-content: center !important;
    }
    .book-now-btn{
      align-self: center;
      margin-top: 1rem ;
      width: 100% !important;
      padding: 12px 0;
      margin-right: 1rem ;
      font-size: 1.2rem;
      text-transform: uppercase;
      font-weight: 500;
      letter-spacing: 2px;
    }
  }
  .custom-image-container {
    display: grid;
    grid-auto-columns: 1fr;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    gap: 10px 10px;
    max-height: 50vh;
    grid-template-areas:
      "big-image big-image"
      "small-image small-image2"
      "small-image3 small-image4";
  }
  .custom-details-container {
    flex-direction: column !important;
    .custom-details-left,
    .custom-details-right {
      width: 100%;
    }
    .custom-details-left {
      .custom-details-content {
        justify-content: space-between !important;
        .custom-info-card {
          margin-bottom: 1rem;
        }
      }
    }
    .custom-description-container {
      width: 100%;
    }
  }
  .custom-ameneties-container {
    .custom-amenity {
      height: 3rem;
      border: 1px solid #b8b6b6;
      width: 11rem !important;
      border-radius: 5px;
      margin-right: 10px !important;
    }
  }
}
</style>
