<template>
  <div id="individual-map"></div>
</template>

<script>
import mapboxgl from "mapbox-gl";
export default {
  data() {
    return {
      mapToken:
        "pk.eyJ1IjoicmlzaGktcmFqLXJveSIsImEiOiJja3N1ZGdsNmwxZXZrMzJvZHhlMDh1M2tjIn0.V1a6wQRKv3J-fNnSGWY6ig",
      map: {},
      latitude: 0,
      longitude: 0,
    };
  },

  methods: {
    loadMap() {
      console.log("Map loading!");
      try {
        mapboxgl.accessToken = this.mapToken;
        //this.setMarkeronMap();

        console.log("entering here");
        this.map = new mapboxgl.Map({
          container: "individual-map", // container ID
          style: "mapbox://styles/mapbox/streets-v11", // style URL
          center: [this.longitude, this.latitude], // starting position [lng, lat]
          zoom: 13, // starting zoom
        });
        const geolocate = new mapboxgl.GeolocateControl({
          positionOptions: {
            enableHighAccuracy: true,
          },
          trackUserLocation: false,
        });
        console.log("Map value is:", this.map);

        const marker = new mapboxgl.Marker({ color: "black", rotation: 45 })
          .setLngLat([this.longitude, this.latitude])
          .addTo(this.map);

        console.log("added to map");
        this.map.addControl(geolocate, "top-right");

        this.map.addControl(new mapboxgl.NavigationControl());
      } catch (err) {
        console.log("Error in Map", err);
      }
    },
  },
  mounted() {
    console.log(this.latitude1, this.longitude1);
    this.latitude = this.latitude1;
    this.longitude = this.longitude1;
    this.loadMap();
  },

  props: ["latitude1", "longitude1"],
};
</script>

<style lang="scss" scoped>
#individual-map {
  width: 100%;
  margin: 0 auto;
  min-height: 30rem;
  max-height: 30rem;
  background-color: #fff;
  overflow: hidden;
}
@media only screen and (max-width: 550px) {
  #individual-map {
    width: 100%;
    min-height: 25rem;
    max-height: 25rem;
  }
}
</style>
