<template>
  <div class="w-100 position-relative">
    <div class="d-flex flex-column align-items-center">
      <div class="custom-small-box d-flex position-relative">
        <div
          class="daily active w-50 p-1"
          @click="toggleDailyAndMonthly('daily')"
        >
          <div class="heading text-center font-semibold">Holiday</div>
          <div class="para text-center">Anything less than 1 month</div>
        </div>
        <div class="monthly w-50 p-1" @click="toggleDailyAndMonthly('monthly')">
          <div class="heading text-center font-semibold">Urban</div>
          <div class="para text-center">Anything starting from 1 month</div>
        </div>
      </div>
      <div
        v-if="dailyActive"
        class="
          w-75
          custom-big-box
          d-flex
          position-relative
          justify-content-between
          align-items-center
        "
      >
        <div class="custom-input location-input">
          <!-- <input type="text" class="bg-color-light1" id="location" /> -->
            <!-- <v-select 
              :options="cities" 
              class="style-chooser"
              label="title"
              :value="selectedCity"
            ></v-select> -->
            <b-form-select 
            style="width:100%; height:3.5rem; border: none; background: #f8f9fd; border-radius:4px; outline: none;" 
            v-model="selectedCity"
            value-field="value"
            text-field="text" 
            :options="cities"></b-form-select>
          <div
            class="custom-label position-absolute d-flex align-items center"
            v-if="city.length <= 0"
          ></div>
        </div>
        <!-- <div class="custom-input">
          <input type="text" class="bg-color-light1" />
          <div class="custom-label position-absolute d-flex align-items center">
            <div class="color-violet">$</div>
            <div class="color-dark">Check In - Check Out</div>
          </div>
        </div> -->
        <div class="custom-input guest-location-input">
          <input type="text" class="bg-color-light1 pe-none" readonly />
          <div class="custom-label position-absolute d-flex align-items center">
            <div class="color-dark">
              {{ numberOfGuests }}
              {{ numberOfGuests === 1 ? "Guest" : "Guests" }}
            </div>
          </div>
          <div
            class="
              custom-button-container
              position-absolute
              d-flex
              align-items-center
            "
          >
            <div class="custom-guests-button" @click="addNumberOfGuests">+</div>
            <div class="custom-guests-button" @click="subNumberOfGuests">-</div>
          </div>
        </div>
        <div
          class="
            custom-search-button
            d-flex
            justify-content-center
            align-items-center
            color-white
            bg-color-violet
            rounded
          "
          @click="useGetListings"
        >
          Search
        </div>
      </div>
      <div
        v-if="monthlyActive"
        class="
          w-100
          custom-big-box
          d-flex
          position-relative
          justify-content-between
          align-items-center
        "
      >
        <!-- <div class="custom-input">
          <input type="text" class="bg-color-light1" id="mlocation" />
          <div
            class="custom-label position-absolute d-flex align-items center"
            v-if="city.length <= 0"
          ></div>
        </div> -->
        <b-form-select 
            style="width:100%; max-width:15rem; height:3.5rem; border: none; background: #f8f9fd; border-radius:4px; outline: none;" 
            v-model="selectedCity"
            value-field="value"
            text-field="text" 
            :options="cities"></b-form-select>
        <!-- <div class="custom-input">
          <input type="text" class="bg-color-light1" />
          <div class="custom-label position-absolute d-flex align-items center">
            <div class="color-violet">$</div>
            <div class="color-dark">Check In - Check Out</div>
          </div>
        </div> -->
        <div class="custom-input">
          <input type="text" class="bg-color-light1 pe-none" readonly />
          <div class="custom-label position-absolute d-flex align-items center">
            <div class="color-dark">
              {{ numberOfGuests }}
              {{ numberOfGuests === 1 ? "Guest" : "Guests" }}
            </div>
          </div>
          <div
            class="
              custom-button-container
              position-absolute
              d-flex
              align-items-center
            "
          >
            <div class="custom-guests-button" @click="addNumberOfGuests">+</div>
            <div class="custom-guests-button" @click="subNumberOfGuests">-</div>
          </div>
        </div>
        <div class="custom-furnish d-flex position-relative">
          <div
            class="
              furnished
              active
              d-flex
              justify-content-center
              align-items-center
              w-50
            "
            @click="toggleFurnishedStatus('furnished')"
          >
            Furnished
          </div>
          <div
            class="
              unfurnished
              d-flex
              justify-content-center
              align-items-center
              w-50
            "
            @click="toggleFurnishedStatus('unfurnished')"
          >
            Unfirnished
          </div>
        </div>
        <div
          class="
            custom-search-button
            d-flex
            justify-content-center
            align-items-center
            color-white
            bg-color-violet
            rounded
          "
          @click="useGetListings"
        >
          Search
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      dailyActive: true,
      monthlyActive: false,
      numberOfGuests: 1,
      furnished: true,
      unFurnished: false,
      page: 1,
      city: "",
      options: {
        fields: ["cities"],
      },
      coordinates: {},
      cities: [
        { text: "Bangalore", value: "Bangalore" },
        { text: "Hosur", value: "Hosur" },
        { text: "Chikmagalur", value: "Chikmagalur" },
        { text: "Lonavala", value: "Lonavala" },
        { text: "Goa", value: "Goa" },
        { text: "Bikaner", value: "Bikaner" },
        { text: "Jodhpur", value: "Jodhpur" },
        { text: "Delhi", value: "Delhi" },

      ],
      selectedCity:"Bangalore",
    };
  },
  methods: {
    toggleDailyAndMonthly(item) {
      if (item === "daily") {
        const element = document.querySelector(".daily");
        element.classList.add("active");
        const remElement = document.querySelector(".monthly");
        remElement.classList.remove("active");
        this.monthlyActive = false;
        this.dailyActive = true;
      } else {
        const element = document.querySelector(".monthly");
        element.classList.add("active");
        const remElement = document.querySelector(".daily");
        remElement.classList.remove("active");
        this.monthlyActive = true;
        this.dailyActive = false;
      }
    },
    addNumberOfGuests() {
      this.numberOfGuests += 1;
    },
    subNumberOfGuests() {
      if (this.numberOfGuests != 1) {
        this.numberOfGuests -= 1;
      }
    },
    toggleFurnishedStatus(item) {
      if (item === "furnished") {
        const element = document.querySelector(".furnished");
        element.classList.add("active");
        const remElement = document.querySelector(".unfurnished");
        remElement.classList.remove("active");
        this.furnished = true;
        this.unFurnished = false;
      } else {
        const element = document.querySelector(".unfurnished");
        console.log(element.classList);
        element.classList.add("active");
        const remElement = document.querySelector(".furnished");
        remElement.classList.remove("active");
        this.furnished = false;
        this.unFurnished = true;
      }
    },
    useGetListings() {
      const reqBody = {
        city: this.city.split(",")[0],
        guests: this.numberOfGuests,
        furnished: this.furnished ? 0 : 1,
      };
      this.$router.push({
        name: "Listings",
        query: {
          // city: this.dailyActive
          //   ? document.getElementById("location").value.split(",")[0]
          //   : document.getElementById("mlocation").value.split(",")[0],
          city: this.selectedCity,
          guests: this.numberOfGuests,
          furnished: this.furnished,
          duration_type: this.dailyActive ? "daily" : "monthly",
          latitude: this.coordinates.latitude,
          longitude: this.coordinates.longitude
        },
      });
    },
  },

  mounted() {
    let self=this;
    let autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("location"),
    );
    google.maps.event.addListener(autocomplete, "place_changed", function () {
      var place = autocomplete.getPlace();
      self.coordinates.latitude = place.geometry.location.lat();
      self.coordinates.longitude = place.geometry.location.lng();
      console.log(self.coordinates)
    });
    let mautocomplete = new google.maps.places.Autocomplete(
      document.getElementById("mlocation"),
    );
    google.maps.event.addListener(mautocomplete, "place_changed", function () {
      var place = mautocomplete.getPlace();
      self.coordinates.latitude = place.geometry.location.lat();
      self.coordinates.longitude = place.geometry.location.lng();
      console.log(self.coordinates)
    });
  },
};
</script>
<style lang="scss" scoped>
.custom-small-box {
  height: 3rem;
  width: 22rem;
  background-color: #fff;
  color: black;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  overflow: hidden;
  .daily {
    color: #303041;
    background-color: #e9e7fd;
    .para {
      font-size: 0.5rem;
    }
    &.active {
      color: #6345fb;
      background-color: #fff;
    }
  }
  .monthly {
    color: #303041;
    background-color: #e9e7fd;
    .para {
      font-size: 0.5rem;
    }
    &.active {
      color: #6345fb;
      background-color: #fff;
    }
  }
}
.custom-big-box {
  height: 7rem;
  background-color: #fff;
  color: black;
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(#000000, 0.2);
  padding: 1rem 1rem;
}
.custom-input {
  position: relative;
  margin-right: 1rem;
  input {
    padding: 0.85rem 1rem;
    border-radius: 5px;
    outline: none;
    height: 3.6rem;
    color: rgba(#000000, 0.5);
    border: none;
    &:focus,
    &:active {
      border: 1px solid rgba(#000000, 0.2);
    }
    width: 100%;
  }
  .custom-label {
    top: 50%;
    left: 10%;
    transform: translate(0%, -50%);
    color: rgba(#000000, 0.5);
    pointer-events: none;
  }
  .custom-button-container {
    top: 50%;
    right: 5%;
    transform: translate(0%, -50%);
    .custom-guests-button {
      margin: 0 0.5rem;
      font-size: 1.4rem;
      color: #6345fb;
      cursor: pointer;
    }
  }
}

  .location-input{
    width: 50%;
  }
  .guest-location-input{
    width: 25%;
  }
.custom-search-button {
  border-radius: 5px;
  height: 3.4rem;
  width: 25%;
  font-size: 1.2rem;
  cursor: pointer;
}
.custom-furnish {
  width: 18rem;
  height: 3.6rem;
  border: 2px solid #6345fb;
  border-radius: 10rem;
  overflow: hidden;
  cursor: pointer;
  .furnished {
    &.active {
      color: #fff;
      background-color: #6345fb;
    }
  }
  .unfurnished {
    &.active {
      color: #fff;
      background-color: #6345fb;
    }
  }
}


@media only screen and (max-width: 578px) {
  .custom-big-box {
    width: 100% !important;
    height: 20rem;
    flex-direction: column !important;
    border-radius: 0px 0px 10px 10px;
  }
  .custom-input {
    margin-right: 0;
    width: 100%;
  }
  .custom-search-button {
    width: 100% !important;
  }
}
</style>
