<template>
  <div class="custom-container">
    <div class="font-size-big-small text-center font-semibold mb-4">
      Other Recommended Options
    </div>
    <div class="custom-width d-flex justify-content-between">
      <div class="custom-card relative">
        <div
          class="left d-flex justify-content-center align-items-center relative"
        >
          <img :src="other1" alt="no image" class="side-image" />
        </div>
        <div class="right d-flex justify-content-center flex-column px-2">
          <div class="special-header font-semibold mb-1">Random Title hai</div>
          <div class="font-size-small">
            Lorem ipsum dolor sit amet, consetetur sadipsci
          </div>
        </div>
      </div>
      <div class="custom-card relative">
        <div
          class="left d-flex justify-content-center align-items-center relative"
        >
          <img :src="other1" alt="no image" class="side-image" />
        </div>
        <div class="right d-flex justify-content-center flex-column px-2">
          <div class="special-header font-semibold mb-1">Random Title hai</div>
          <div class="font-size-small">
            Lorem ipsum dolor sit amet, consetetur sadipsci
          </div>
        </div>
      </div>
      <div class="custom-card relative">
        <div
          class="left d-flex justify-content-center align-items-center relative"
        >
          <img :src="other1" alt="no image" class="side-image" />
        </div>
        <div class="right d-flex justify-content-center flex-column px-2">
          <div class="special-header font-semibold mb-1">Random Title hai</div>
          <div class="font-size-small">
            Lorem ipsum dolor sit amet, consetetur sadipsci
          </div>
        </div>
      </div> 
    </div>
  </div>
</template>
<script>
import other1 from "@/assets/other1.jpg";
export default {
  data() {
    return {
      other1,
    };
  },
};
</script>
<style lang="scss" scoped>
.custom-container {
  width: 100%;
  min-height: 40vh;
}
.custom-card {
  width: 24rem;
  max-width: 100%;
  height: 8rem;
  border-radius: 1rem;
  box-shadow: 0px 0px 30px -3px rgba($color: #000000, $alpha: 0.1);
  padding: 10px 1rem;
  display: flex;
  margin-bottom: 2rem;
  .left {
    width: 30%;
    height: 100%;
    img {
      height: 6.5rem;
      width: 6.5rem;
      border-radius: 1rem;
      object-fit: cover;
    }
  }
  .right {
    width: 70%;
    height: 100%;
  }
}

.custom-width {
  width: 80%;
  margin: 0 auto;
  padding-top: 3rem;
  flex-wrap: wrap;
}
</style>
