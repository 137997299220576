<template>
    <div class="custom-property-card d-flex justify-content-center align-items flex-column" @click="sendpropertyById">
        <div class="custom-top position-relative">
            <Carousel :per-page="1"  :autoplayTimeout="4000" :loop="true" :autoplay="true" :mouse-drag="true" style="height:20rem;">
                    <Slide class="custom-property-image " v-for="(item, index) in property.images" :key="index">
                        <img :src="item.image_url" >
                    </Slide>
            </Carousel>
        </div>
        
        <div class="custom-body">
            <div class="pricing d-flex align-items-end mb-2">
                <div class="price">
                    <span class="currency">INR </span>
                    <span class="amount">{{property.property_price ? property.property_price : '-'}} </span>
                </div>
                <div class="duration">
                    <span class="unit"><small>/</small></span>
                    <span class="value"><small>{{ durationType == 'monthly'? 'monthly' : 'daily' }}</small></span>
                </div>
            </div>
            <div class="description ">
                <p style="font-weight:600;">{{ property.property_name ? property.property_name : "Random House" }}</p>
            </div>
            <div class="location d-flex align-items-center">
                <img :src="Marker" alt="Marker">
                <div>{{ property.property_location.city}}, {{property.property_location.state}}, {{property.property_location.country }}</div>
            </div>
            <div class="property-footer d-flex align-items-center justify-content-end">
                <div class="d-flex justify-content-between align-items-center">
                    <div class="property-item d-flex justify-content-center align-items-center mr-2">
                        <img :src="Rooms" alt="Rooms" />
                        <small class="font-semibold">{{ property.rooms ? property.rooms : 0 }}</small>
                    </div>
                    <div class="property-item d-flex justify-content-center align-items-center mr-2">
                        <img :src="Shower" alt="Rooms" style="height:1rem;" />
                        <small class="font-semibold">{{ property.baths ? property.baths : 0 }}</small>
                    </div>
                    <div class="property-item d-flex justify-content-center align-items-center mr-2">
                        <img :src="Windows" alt="Rooms" style="height:1rem;" />
                        <small class="font-semibold">{{ property.balconies ? property.balconies : 0 }}</small>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import Marker from '@/assets/location-marker.png';
import Rooms from '@/assets/furniture.png';
import Shower from '@/assets/shower.png';
import Windows from '@/assets/curtains.png';
import { Carousel, Slide } from 'vue-carousel';
export default {
    data() {
        return {
            Marker,
            Rooms,
            Shower,
            Windows
        }
    },
    props:['property','durationType'],
    methods:{
        sendpropertyById(){
            this.$router.push({path:'/property/'+this.property.property_id});
        }
    },
    components:{
        Carousel,
        Slide
    }
}
</script>
<style lang="scss" scoped>
    .custom-property-card{
        width: 90%;
        height: 25rem;
        margin-top: 2rem;
        background-color: #fff;
        border-radius: 10px;
        box-shadow: 0px 0px 10px 0px #ccc;
        overflow: hidden;
        box-shadow: 0px 0px 50px -5px rgba($color: #000000, $alpha: 0.17);
        cursor: pointer;
        overflow: hidden;
        .custom-top{
            height: 55%;
            overflow: hidden;
            width: 100%;
            background-color: lightcoral;
            position: relative;
        }
        .custom-body{
            height: 45%;
            overflow: hidden;
            width: 100%;
            position: relative;
            padding: 1rem;
            .pricing{
                color: #3C3CE1;
                .price{
                    font-weight: 500;
                }
            }
            .description{
                font-size: 1.2rem;
                margin-bottom: 1rem;
            }
            .location{
                opacity: 0.5;
                font-size: 0.8rem;
                img{
                    height: 1.1rem;
                    width: auto;
                    margin-right: 5px;
                }
            }
            .property-footer{
                height: 3rem;
                width: 100%;
                position: absolute;
                bottom: 0;
                left: 0;
                border-top: 2px solid rgb(224, 223, 223);
                color: #3C3CE1;
                padding: 0 1rem;
                .property-item{
                    margin-right: 1.3rem;
                    img{
                        height: 1.1rem;
                        width: auto;
                        margin-right: 5px;
                    }
                }
            }
                
        }
    }
    .custom-property-image{
        height: 120% !important;
        img{
            height: 15rem !important;
            width: 100% !important;
            object-fit: cover !important;
        }
    }
</style>