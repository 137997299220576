<template>
  <div>
    <NavbarCommon />
    <NavbarSmall />
    <div class="custom-wrapper position-relative d-flex justify-content-start" v-if="listings.length > 0">
      <div class="custom-listings-container d-flex flex-column">
        <Search 
        :parameterChangeFunction="parameterChangeFunction" 
        :setNextPage="setNextPage" 
        :setPriceRange="setPriceRange" 
        :durationType="duration_type" 
        :setDurationType="setDurationType" 
        :setEntirePrivate="setEntirePrivate" 
        :isPrivate="isPrivate"
        />
        <Properties :properties="listings" :hasNextPage="hasNextPage" :setNextPage="setNextPage" :getListingsFromParam="getListingsFromParam" :buttonLoading="buttonLoading" :page="page" :totalRows="totalRows" :durationType="duration_type" />
      </div>
      <div class="custom-listings-map" v-if="listings.length > 0">
        <AllListingMap :setCentral="setCentral" :listings="listings" :coordinates="coordinates" :durationType="duration_type"/>
      </div>
      <!-- <div v-if="!isMapEnabled" class="custom-listings-map d-flex justify-content-center align-items-center text-center" style="font-size:24px;">
        Please Enable Location and try Again
      </div> -->
    </div>
    <div v-if="listings.length == 0" class="d-flex justify-content-center align-items-center" style="height:80vh;">
      <span class="custom-no-listings-message">
        No listings found.
      </span>
    </div>
    <div class="custom-footer-container w-100">
      <Footer />
    </div>
    <div v-if="isLoading">
      <MainLoader />
    </div>
  </div>
</template>
<script>
import Search from "../components/SearchComponent/SearchComponent.vue";
import MainLoader from "../components/MainLoader/MainLoader.vue";
import Properties from "../components/Properties/Properties.vue";
import Footer from "../components/Footer/Footer.vue";
import NavbarCommon from "../components/NavbarCommon/NavbarCommon.vue";
import NavbarSmall from "../components/NavbarSmall/NavbarSmall.vue";
import AllListingMap from "../components/AllListingsMap/AllListingMap.vue";
import { getListingsByCity } from "@/utils/apis.js";
export default {
  data() {
    return {
      listings: [],
      map: null,
      //map variables
      centralLatitude: 0,
      centralLongitude: 0,
      page: 1,
      buttonLoading: false,


      hasNextPage: true,
      city:'',
      furnished:'',
      guests:'',
      duration_type:'daily',
      price:100,
      priceSort:'high',
      rooms:'',
      baths:'',
      isPrivate:true,
      isMapEnabled:false,
      totalRows:0,
      isLoading:true,
    };
  },

  methods: {
    setCentral(lat, lng) {
      this.centralLatitude = lat;
      this.centralLongitude = lng;
    },

    setVariablesFromQuery(){
        this.city = this.$route.query.city ? this.$route.query.city : '';
        this.furnished = this.$route.query.furnished ? this.$route.query.furnished : "";
        this.guests = this.$route.query.guests ? this.$route.query.guests : 1;
        this.duration_type = this.$route.query.duration_type ? this.$route.query.duration_type : 'daily';
        this.coordinates = {latitude: this.$route.query.latitude ? this.$route.query.latitude : '28.7041', longitude: this.$route.query.longitude ? this.$route.query.longitude : '77.1025'}
        this.getListingsFromParam();

    },




    async getListingsFromParam() {
      this.buttonLoading=true;


      try {
        const reqBody = {
          city: this.city,
          furnished: this.furnished,
          guests: this.guests,
          duration_type: this.duration_type,
          price: this.price,
          price_sort : this.priceSort
        };

        if(this.rooms){
          reqBody.rooms = this.rooms
        }

        if(this.baths){
          reqBody.baths = this.baths
        }

        if(this.isPrivate){
          // reqBody.is_private = this.isPrivate;
        }



        const res = await getListingsByCity(reqBody, this.page);
        console.log("&*^%", res);
        if(res.success){
        const newListings = res.items
        this.coordinates = {latitude: res.items[0].property_location.latitude, longitude: res.items[0].property_location.longitude}
        this.listings=[...newListings];
        this.totalRows = res.count;
        this.buttonLoading = false;
        }
        else{
            this.hasNextPage = false;
            this.buttonLoading=false;
        }

       
    
      } catch (error) {
        this.buttonLoading=false;
        this.hasNextPage = false;
        console.log("error", error);
        
        // this.$router.push("/");
      }
        this.isLoading = false;

    },

    parameterChangeFunction(room,bath,isPrivate,priceChange,priceSortChange){

        this.rooms = room;
        this.baths = bath;
        this.isPrivate = isPrivate;
        // this.price = priceChange;
        this.priceSort = priceSortChange;


        this.page = 1;
        this.getListingsFromParam();
    },

    setPriceRange(priceRange){
      this.price = priceRange;
      this.page = 1;
      this.getListingsFromParam();
    },
    setNextPage(pageNo){
        this.page = pageNo;
    },
    setDurationType(durationType){
      this.duration_type = durationType;
      this.page = 1;
      this.getListingsFromParam();
    },
    setEntirePrivate(val){
      this.isPrivate = val;
      this.page = 1;
    }
  },

  watch: {
    centralLongitude: function () {
      console.log("Longitude change, api call now");
    },
    centralLatitude: function () {
      console.log("Latitude change, api call now");
    },

  },

  components: {
    Search,
    Properties,
    Footer,
    NavbarCommon,
    AllListingMap,
    MainLoader,
    NavbarSmall,
  },
  mounted() {
    
      this.setVariablesFromQuery();
      window.scroll(0,0);
      // let self = this;
      // navigator.geolocation.watchPosition(function(position) {
      // console.log("i'm tracking you!");
      // self.isMapEnabled = true
      // },
      // function(error) {
      //   if (error.code == error.PERMISSION_DENIED)
      //     alert("Please enable location")
      //     self.isMapEnabled=false
      // });
  },
};
</script>
<style lang="scss" scoped>
.custom-nav-container {
  height: 4rem;
  box-shadow: 0px 5px 10px -5px rgba($color: #000000, $alpha: 0.13);
}
.custom-wrapper {
  height: 200vh;
  width: 100%;
  margin: 0 auto;
  .custom-listings-container {
    height: 100%;
    overflow: auto;
    width: 60%;
    position: relative;
  }
  .custom-listings-map {
    height: 100vh;
    position: sticky;
    top: 3rem;
    overflow: auto;
    width: 40%;
    background-color: rgba($color: #000000, $alpha: 0.3);
  }
}
.custom-no-listings-message{
  font-size: 24px;
  text-align: center;
  color: #000000;
}
@media only screen and (max-width: 578px) {
  .custom-wrapper {
    height: auto;
    min-height: 100vh;
    padding-bottom: 2rem;
    flex-direction: column;
    .custom-listings-container {
      width: 100% !important;
    }
    .custom-listings-map {
      width: 100% !important;
      margin-top: 2rem;
      display: none;
    }
  }
}
</style>
