<template>
  <div
    id="map"
    v-touch:start="getCentralCoordinates"
    v-touch:swipe="getCentralCoordinates"
    @change="getCentralCoordinates"
  ></div>
</template>

<script>
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";

export default {
  data() {
    return {
      mapToken:
        "pk.eyJ1IjoicmlzaGktcmFqLXJveSIsImEiOiJja3N1ZGdsNmwxZXZrMzJvZHhlMDh1M2tjIn0.V1a6wQRKv3J-fNnSGWY6ig",
      map: {},
      latitude: 0,
      longitude: 0,
      marker: "",
      centralLongitude: 0,
      centralLatitude: 0,
      userLatitude: 0,
      userLongitude: 0,
      propertyListing: [],
      duration_type: "daily",
    };
  },

  methods: {
    loadMap() {
      try {
        // if ("geolocation" in navigator) {
        //   navigator.geolocation.getCurrentPosition((position) => {
            mapboxgl.accessToken = this.mapToken;
            // this.latitude = position.coords.latitude;
            // this.longitude = position.coords.longitude;
            this.latitude = 28.704121;
            this.longitude = 77.102534;
            console.log(this.coordinates.latitude);
            console.log(this.coordinates.longitude);
            console.log("Entering here");
            this.map = new mapboxgl.Map({
              container: "map",
              style: "mapbox://styles/mapbox/streets-v11",
              center: [this.coordinates.longitude, this.coordinates.latitude],
              zoom: 12,
              maxZoom: 14,
              minZoom: 5,
            });

            const geolocate = new mapboxgl.GeolocateControl({
              positionOptions: {
                enableHighAccuracy: true,
              },
              trackUserLocation: false,
            });

            this.map.addControl(geolocate, "top-right");
            this.map.addControl(new mapboxgl.NavigationControl());
            console.log("Property Listing here:", this.propertyListing);
            this.propertyListing.forEach((property) => {
              // console.log('Traversing!');
              var popup = new mapboxgl.Popup({ offset: [10, -50 / 2] })
                .setHTML(`<div class="mapbox-custom-container">
                                    <div class="mapbox-custom-card">
                                        <div class="mapbox-custom-top-image">
                                            <img src=${property.images[0].image_url ? property.images[0].image_url : 'https://images.pexels.com/photos/1133957/pexels-photo-1133957.jpeg?auto=compress&cs=tinysrgb&dpr=1&w=500' } alt="no image" />
                                        </div>
                                        <div class="mapbox-custom-card-bottom">
                                        <div class="mapbox-custom-card-top-reviews">
                                            <span style="color:blueviolet;">&#9733;</span>
                                            
                                            
                                            
                                        </div>
                                        <div>
                                            <div class="mapbox-custom-property-title">
                                            ${property.property_name}
                                            </div>
                                        </div>
                                        <div class="mapbox-custom-pricing-wrapper">
                                            <span class="mapbox-custom-price-bold">INR</span>
                                            <span class="mapbox-custom-price-bold">${ property.property_price }</span>
                                            <span class="mapbox-custom-pricing-small"><small>  </small></span>
                                        </div>
                                        <a href="http://localhost:8080/property/${property.property_id}">View Listing</a>
                                        <div></div>
                                        </div>
                                    </div>
                                    </div>`);
              var el = document.createElement("div");
              el.classList.add("marker");
              el.innerHTML = `INR ${ property.property_price }`;

              new mapboxgl.Marker(el)
                .setLngLat([
                  property.property_location.longitude,
                  property.property_location.latitude,
                ])
                .setPopup(popup)
                .addTo(this.map);
            });
        
      } catch (err) {
        console.log(err);
        console.log("Error in Map!",err);
      }
    },
    getCentralCoordinates() {
      const center = this.map.getCenter();
      this.centralLongitude = center.lng;
      this.centralLatitude = center.lat;
      console.log(
        "Lat:",
        this.centralLatitude,
        " Long:",
        this.centralLongitude
      );
    },
  },

  watch: {
    centralLongitude: function () {
      this.setCentral(this.centralLatitude, this.centralLongitude);
    },
    centralLatitude: function () {
      this.setCentral(this.centralLatitude, this.centralLongitude);
    },
    durationType: function () {
      console.log("Duration Type:", this.durationType);
      this.duration_type = this.durationType ? this.durationType : "daily";
    },
  },
  mounted() {
    //this.getCurrentCoordinates();
    console.log("Listings here:", this.listings);
    this.propertyListing = this.listings;
    this.loadMap();
    console.log("panner", durationType);
  },

  props: ["setCentral", "listings", "coordinates", "durationType"],
};
</script>

<style>
#map {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.marker {
  background-color: #fff;
  border: 2px solid blueviolet;
  color: blueviolet;
  font-weight: bold;
  transition: 0.3s ease;
  background-size: cover;
  min-width: 50px;
  min-height: 16px;
  border-radius: 2rem;
  cursor: pointer;
  text-align: center;
  padding: 5px 10px;
}

.marker:hover {
  color: #fff;
  background-color: blueviolet;
}

.mapboxgl-popup {
  max-width: 500px;
}

.mapboxgl-popup-content {
  width: 21.3rem;
}

/* .mapbox-custom-container{
  width:100%;
  height:100vh;
  display:flex;
  justify-content:center;
  align-items:center;
  
} */
.mapbox-custom-card {
  width: 20rem;
  height: 18rem;
  background: #fff;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(1, 1, 1, 0.1);
  position: relative;
  overflow: hidden;
}

.mapbox-custom-top-image {
  width: 100%;
  height: 60%;
}
.mapbox-custom-top-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mapbox-custom-card-bottom {
  padding: 10px;
  height: 40%;
}
.mapbox-custom-property-title {
  margin-top: 10px;
  font-weight: 500;
  font-size: 1.2rem;
}

.mapbox-custom-pricing-wrapper {
  margin-top: 10px;
}

.mapbox-custom-price-bold {
  font-weight: bold;
  color: blueviolet;
  font-size: 1.1rem;
}

.mapbox-custom-pricing-small {
  color: blueviolet;
}
</style>