<template>
  <div class="custom-container">
    <div class="card-container">
      <div class="heading">Welcome to FlexiRental!</div>
      <div class="para">
        Please sign-in to your account and start the adventure
      </div>
      <div class="input-container d-flex flex-column">
        <label for="">Email</label>
        <input
          type="email"
          name=""
          id=""
          placeholder="Enter your email"
          v-model="email"
        />
      </div>
      <div class="input-container d-flex flex-column">
        <label for="">Password</label>
        <input
          type="password"
          name=""
          id=""
          placeholder="Enter your password"
          v-model="password"
        />
        <div class="d-flex justify-content-end">
          <router-link
            to="/forgot-password"
            style="text-decoration: none; color: #4256ee; font-size: 0.9rem"
            >Forgot Password?</router-link
          >
        </div>
      </div>
      <div class="checkbox-container d-flex align-items-center">
        <input
          type="checkbox"
          style="margin-right: 5px"
          name=""
          id=""
          @click="rememberMe = !rememberMe"
        />
        <label for="" style="line-height: 0">Remember me</label>
      </div>
      <button type="button" class="login-button w-100 my-4 mt-2 py-2 rounded" @click="useLoginUser">Login</button>
      <div class="mb-2 text-center">
        New on our platform? <router-link to="/register">Create an account</router-link>
      </div>
      <div
        class="custom-lines d-flex align-items-center w-100 position-relative"
      >
        <div class="line w-50"></div>
        <span class="mx-1">or</span>
        <div class="line w-50"></div>
      </div>
      <div class="d-flex justify-content-center">
        <button type="button" class="login-with-google-btn">
            Sign in with Google
        </button>
      </div>
      
    </div>
  </div>
</template>
<script>
import {loginUser} from '@/utils/apis.js';
export default {
  data() {
    return {
      email: "",
      password: "",
      rememberMe: false,
    };
  },
  methods:{
    async useLoginUser(){
      const {email, password} = this;
      const response = await loginUser({email, password});
      if(response.data.access){
        localStorage.setItem('app_access_token', response.data.access);
        localStorage.setItem('app_refresh_token', response.data.refresh);
        this.setLoginModal(false)
      }else{
        alert(response.data.msg ? response.data.msg : "Wrong Credentials")
      }
    }
  },
  props:['setLoginModal']
};
</script>
<style lang="scss" scoped>
.custom-container {
  width: 100% !important;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f8f879;
  position: fixed;
  top: 0%;
  left: 0%;
  z-index: 99999;
}
.card-container {
  width: 27rem;
  min-height: 34rem;
  max-width: 90%;
  background-color: #fff;
  box-shadow: 0px 10px 30px -3px rgba($color: #000000, $alpha: 0.1);
  border-radius: 10px;
  padding: 4rem 2rem 2rem 2rem;
  font-family: "Montserrat", sans-serif;
}
.heading {
  font-weight: 500;
  font-size: 1.125rem;
  color: #5e5873;
  margin-bottom: 1rem;
}
.para {
  font-size: 0.9rem;
  font-weight: 300;
}
.input-container {
  margin-top: 1rem;
  label {
    font-size: 0.9rem;
  }
  input {
    padding: 8px 10px;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
    transition: 0.3s ease;
    &:focus,
    &:active {
      outline: none;
      border: 1px solid #928da7;
    }
  }
}
.checkbox-container {
  input {
    height: 16px;
    width: 16px;
    &:checked {
      background-color: palevioletred;
    }
  }
}
.login-button {
  color: #fff;
  background-color: #4256ee;
  border: none;
}
.custom-lines {
  margin-bottom: 1rem;
  .line {
    height: 0.3px;
    background-color: #cdcbd1;
  }
}
.login-with-google-btn {
  transition: background-color 0.3s, box-shadow 0.3s;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);

  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;

  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 12px 11px;

  &:hover {
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25);
  }

  &:active {
    background-color: #eeeeee;
  }

  &:focus {
    outline: none;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 2px 4px rgba(0, 0, 0, 0.25),
      0 0 0 3px #c8dafc;
  }

  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, 0.04), 0 1px 1px rgba(0, 0, 0, 0.25);
    cursor: not-allowed;
  }
}
</style>
