<template>
  <div id="id02" class="modal-carousel" style="background-color: #000000d4; height:100vh">
    <span
      @click="closePopup"
      class="close-carousel"
      title="Close Modal"
      >×</span
    >
    <Carousel
      :per-page="1"
      :autoplayTimeout="4000"
      :centerMode="true"
      :navigationEnabled="true"
      :loop="true"
      :autoplay="false"
      :mouse-drag="true"
      class="custom-vue-carousel"
    >
      <Slide
        class="custom-property-image"
        v-for="(item, index) in property.images"
        :key="index"
      >
        <div class="img-wrapper">
          <img :src="item.image_url" />
        </div>
      </Slide>
    </Carousel>
  </div>
</template>

<script>
import { Carousel, Slide } from "vue-carousel";

export default {
  methods: {
    logOut(e) {
      e.preventDefault();
      document.getElementById("id02").style.display = "none";
      //   localStorage.removeItem("app_access_token");
      //   localStorage.removeItem("app_refresh_token");
      //   this.changeIsSignedIn();
      //   this.$router.push("/");
    },
    closePopup(e) {
      e.preventDefault();
      document.getElementById("id02").style.display = "none";
      document.body.style.overflow = "visible";

    },
  },
  components: {
    Carousel,
    Slide,
  },
  mounted(){
    document.getElementById('id02').style.display='none'
  },
  props: ["property"],
};
</script>

<style lang="scss">

.modal-carousel {
  display: block; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 90vh; /* Full height */
  overflow: hidden; /* Enable scroll if needed */
  padding-top: 75px;
  z-index: 1000000;
  .VueCarousel-slide {
    display: flex;
    justify-content: center;
  }
  .VueCarousel-inner {
    height: 90% !important;
  }
  .VueCarousel-navigation-button{
      color: white;
      position: absolute;
      margin-top: -3rem;
  }
  .img-wrapper{
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 80%;
    height: 100%;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;

    }
  }
}

/* Modal Content/Box */

/* Style the horizontal ruler */
hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

/* The Modal Close Button (x) */
.close-carousel {
  position: absolute;
  right: 35px;
  top: 15px;
  font-size: 40px;
  font-weight: bold;
  color: #f1f1f1;
}

.close-carousel:hover,
.close-carousel:focus {
  color: #f44336;
  cursor: pointer;
}
.custom-vue-carousel{
  height: 100%; 
  width: 80%; 
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.VueCarousel-pagination { display: none; }
@media only screen and (max-width: 576px) {
  .custom-vue-carousel{
    width: 100%;
  }
  .modal-carousel {
    .img-wrapper{
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      width: 100%;
      height: 100%;
      img{
      width: 100%;
      height: auto;
      object-fit: cover;

      }
    }
  }
  .VueCarousel {
  .VueCarousel-navigation-button {
    display: none;
}
  }
}

</style>