<template>
  <div class="w-100 vh-100 position-relative">
    <div class="custom-login d-flex align-items-center">
      <a class="custom-btn-login" v-if="!isSignedIn" href="/login">Login</a>
      <a class="custom-btn-findhomes" href="/property">Find homes</a>
      <a class="custom-btn-login" v-if="isSignedIn" href="" @click="logOut($event)">Logout</a>
      <LogOutPop :changeIsSignedIn="changeIsSignedIn"/>
    </div>
    <div
      class="
        custom-background
        d-flex
        h-100
        w-100
        flex-column
        justify-content-center
        align-items-center
        color-white
      "
    >
      <div class="text-center font-size-big font-semibold">
        Freedom to Move <br />
        Anywhere
      </div>
      <div class="custom-opacity-1 text-center font-size-mid">
        No Leases. No Landlords. No Commitments.
      </div>
      <div class="w-75">
        <DailyMonthlyBox />
      </div>
    </div>
  </div>
</template>
<script>
import DailyMonthlyBox from "@/components/DailyMonthlyBox/DailyMonthlyBox.vue";
import LogOutPop from '../LogOutPop/LogOutPop.vue';
// import { getUserDetails } from "@/utils/api.js";
export default {
  data(){
    return{
      isSignedIn:false,
    }
  },
  components: {
    DailyMonthlyBox,
    LogOutPop
  },
  methods:{
  logOut(e) {
      e.preventDefault();
      console.log("hi")
      document.getElementById('id01').style.display='block'
      // localStorage.removeItem("app_access_token");
      // localStorage.removeItem("app_refresh_token");
      // this.isSignedIn = false
      // this.$router.push("/");
    },
    changeIsSignedIn(){
      this.isSignedIn= false
    }
  },
  mounted(){
    if(localStorage.getItem("app_access_token")){
      this.isSignedIn = true
    }
  }
};
</script>
<style lang="scss" scoped>
.custom-login{
  height: 6rem;
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  padding: 1rem 2rem;
  font-weight: bold;
}
.custom-background {
  background: linear-gradient(to bottom, rgba(#6345fb, 0.3), rgba(#6345fb, 0.3)),
    url(../../assets/HeroBackground.jpg);
  background-size: cover;
}
.custom-opacity-1 {
  opacity: 0.7;
}
.custom-btn-login{
  padding: 10px 25px;
  border-radius: 5px;
  background-color: #6345fb;
  color: #fff;
  cursor: pointer;  
  text-decoration: none;
  margin-right: 1rem;
}
.custom-btn-signup{
  padding: 10px 25px;
  border-radius: 5px;
  background-color: #6345fb;
  color: #fff;
  cursor: pointer;  
  text-decoration: none;
  margin-right: 1rem;
}
.custom-btn-findhomes{
  padding: 10px 25px;
  border-radius: 5px;
  background-color: #fff;
  color:#6345fb;
  cursor: pointer;  
  text-decoration: none;
  margin-right: 1rem;
}
</style>
