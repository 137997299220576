<template>
  <div
    class="
      custom-search-bar
      w-100
      d-flex
      align-items-center
      position-relative
      p-2
      px-4
    "
  >
    <div class="w-100 d-flex justify-content-start">
      <div class="position-relative">
        <div class="custom-listing-filter" @click="openListingsPopup">
          Listing details
        </div>
        <div>
          <div class="custom-listing-items-container d-none">
            <!-- <div class="custom-room-type w-100 position-relative d-flex justify-content-between align-items-center">
                            <div id="custom-entire-room" class="custom-entire-private-room w-100 h-100 d-flex justify-content-center align-items-center" :class="{'active': !isPrivate}" @click="setEntirePrivate(false)">Entire Room</div>
                            <div class="custom-line"></div>
                            <div id="custom-private-room" class="custom-entire-private-room w-100 h-100 d-flex justify-content-center align-items-center" :class="{'active': isPrivate}" @click="setEntirePrivate(true)">Private Room</div> 
                        </div> -->
            <div class="d-flex justify-content-start align-items-center">
                <div class="custom-switch d-flex flex-column" style="margin-right:2rem;">
                    <span style="font-size:1.2rem; font-weight:500;">Wifi</span>
                    <label class="switch">
                        <input type="checkbox" @click="toggleCheckbox(0)">
                        <div class="slider round"></div>
                    </label>
                </div>
                <div class="custom-switch d-flex flex-column mr-3">
                    <span style="font-size:1.2rem; font-weight:500;">AC</span>
                    <label class="switch">
                        <input type="checkbox" @click="toggleCheckbox(1)">
                        <div class="slider round"></div>
                    </label>
                </div>
            </div>
            <div
              class="
                custom-room-type
                w-100
                position-relative
                d-flex
                justify-content-between
                align-items-center
                px-2
              "
            >
              <div class="custom-room-buttons" @click="addBedroom">+</div>
              <div>{{ numberOfBedrooms }} of bedrooms</div>
              <div class="custom-room-buttons" @click="removeBedroom">-</div>
            </div>
            <div
              class="
                custom-room-type
                w-100
                position-relative
                d-flex
                justify-content-between
                align-items-center
                px-2
              "
            >
              <div class="custom-room-buttons" @click="addBathroom">+</div>
              <div>{{ numberOfBathrooms }} of baths</div>
              <div class="custom-room-buttons" @click="removeBathroom">-</div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <div class="custom-reset" @click="resetListingDetails">Reset</div>
              <div class="custom-apply" @click="applyListingDetails">Apply</div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-relative">
        <div class="custom-pricing-filter" @click="openPricingPopup">
          Pricing
        </div>
        <div>
          <div class="custom-pricing-items-container d-none">
            <RangeSlider :setPriceRange="setPriceRange" />
          </div>
        </div>
      </div>
      <div class="position-relative">
        <div
          class="
            custom-room-type
            position-relative
            d-flex
            justify-content-between
            align-items-center
          "
        >
          <div
            id="custom-daily-room"
            class="
              custom-room-type-filter
              w-100
              h-100
              d-flex
              justify-content-center
              align-items-center
            "
            :class="{ active: durationType === 'daily' }"
            @click="setDurationType('daily')"
          >
            Daily
          </div>
          <div class="custom-line"></div>
          <div
            id="custom-monthly-room"
            class="
              custom-room-type-filter
              w-100
              h-100
              d-flex
              justify-content-center
              align-items-center
            "
            :class="{ active: durationType === 'monthly' }"
            @click="setDurationType('monthly')"
          >
            Monthly
          </div>
        </div>
      </div>

      <!-- <div class="position-relative">
                <div class="custom-duration-filter" @click="openDurationPopup">
                    Duration
                </div>
                <div>
                    <div class="custom-duration-items-container d-none">

                    </div>
                </div>
            </div> -->
    </div>
  </div>
</template>
<script>
import RangeSlider from "@/components/RangeSlider/RangeSlider.vue";

export default {
  data() {
    return {
      numberOfBedrooms: 0,
      numberOfBathrooms: 0,
      entireRoom: false,
      privateRoom: false,
      price: 100000,
      priceSort: "high",
      isWifi: false,
      isAC: false,
    };
  },
  methods: {
    openListingsPopup() {
      document
        .querySelector(".custom-listing-items-container")
        .classList.toggle("d-none");
      document
        .querySelector(".custom-listing-filter")
        .classList.toggle("custom-violet-bg");

      document
        .querySelector(".custom-pricing-items-container")
        .classList.add("d-none");
      document
        .querySelector(".custom-pricing-filter")
        .classList.remove("custom-violet-bg");
      document
        .querySelector(".custom-duration-items-container")
        .classList.add("d-none");
      document
        .querySelector(".custom-duration-filter")
        .classList.remove("custom-violet-bg");
    },
    openPricingPopup() {
      document
        .querySelector(".custom-pricing-items-container")
        .classList.toggle("d-none");
      document
        .querySelector(".custom-pricing-filter")
        .classList.toggle("custom-violet-bg");

      document
        .querySelector(".custom-listing-items-container")
        .classList.add("d-none");
      document
        .querySelector(".custom-listing-filter")
        .classList.remove("custom-violet-bg");
      document
        .querySelector(".custom-duration-items-container")
        .classList.add("d-none");
      document
        .querySelector(".custom-duration-filter")
        .classList.remove("custom-violet-bg");
    },
    openDurationPopup() {
      document
        .querySelector(".custom-duration-items-container")
        .classList.toggle("d-none");
      document
        .querySelector(".custom-duration-filter")
        .classList.toggle("custom-violet-bg");

      document
        .querySelector(".custom-listing-items-container")
        .classList.add("d-none");
      document
        .querySelector(".custom-listing-filter")
        .classList.remove("custom-violet-bg");
      document
        .querySelector(".custom-pricing-items-container")
        .classList.add("d-none");
      document
        .querySelector(".custom-pricing-filter")
        .classList.remove("custom-violet-bg");
    },
    openEntireRoom() {
      document
        .querySelector("#custom-entire-room")
        .classList.toggle("custom-violet-bg");
      document
        .querySelector("#custom-private-room")
        .classList.remove("custom-violet-bg");
      this.entireRoom = true;
      this.privateRoom = false;
    },
    openPrivateRoom() {
      document
        .querySelector("#custom-private-room")
        .classList.toggle("custom-violet-bg");
      document
        .querySelector("#custom-entire-room")
        .classList.remove("custom-violet-bg");
      this.entireRoom = false;
      this.privateRoom = true;
    },
    addBedroom() {
      this.numberOfBedrooms++;
    },
    removeBedroom() {
      if (this.numberOfBedrooms > 0) {
        this.numberOfBedrooms--;
      }
    },
    addBathroom() {
      this.numberOfBathrooms++;
    },
    removeBathroom() {
      if (this.numberOfBathrooms > 0) {
        this.numberOfBathrooms--;
      }
    },
    applyListingDetails() {
      this.setNextPage(1);
      this.parameterChangeFunction(
        this.numberOfBedrooms,
        this.numberOfBathrooms,
        this.isPrivate,
        this.price,
        this.priceSort
      );
    },
    resetListingDetails() {
      this.numberOfBedrooms = 0;
      this.numberOfBathrooms = 0;
      this.entireRoom = false;
      this.privateRoom = false;
      document
        .querySelector("#custom-entire-room")
        .classList.remove("custom-violet-bg");
      document
        .querySelector("#custom-private-room")
        .classList.remove("custom-violet-bg");
    },

    toggleCheckbox(id) {
    if(id === 0){
        this.isWifi = !this.isWifi;
        this.$emit("setCheckboxVal", this.isWifi);
    }
    else if(id === 1){
        this.isAC = !this.isAC;
        this.$emit("setCheckboxVal", this.isAC);
    }
    },
  },
  components: {
    RangeSlider,
  },
  props: [
    "parameterChangeFunction",
    "setNextPage",
    "setPriceRange",
    "durationType",
    "setDurationType",
    "setEntirePrivate",
    "isPrivate",
  ],
};
</script>
<style lang="scss" scoped>
.custom-search-bar {
  height: 5rem;
  border-bottom: 1px solid rgba($color: #000000, $alpha: 0.1);
}
.custom-width-sm {
  width: 35%;
}
.custom-width-lg {
  width: 65%;
}
.custom-listing-filter {
  padding: 8px 1rem;
  border: 2px solid #3939e2;
  display: inline-block;
  border-radius: 2rem;
  cursor: pointer;
  margin-right: 2rem;
}
.custom-listing-items-container {
  width: 20rem;
  min-height: 20rem;
  border-radius: 1rem;
  background-color: #fff;
  padding: 1.5rem 1rem;
  box-shadow: 0px 10px 100px -5px rgba($color: #000000, $alpha: 0.1);
  border: 2px solid rgba($color: #000000, $alpha: 0.1);
  position: absolute;
  z-index: 1000;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.custom-room-type-filter {
  &.active {
    color: #fff;
    background-color: #3939e2;
  }
}
.custom-pricing-filter {
  padding: 8px 2rem;
  border: 2px solid #3939e2;
  display: inline-block;
  border-radius: 2rem;
  cursor: pointer;
  margin-right: 2rem;
}
.custom-pricing-items-container {
  width: 20rem;
  height: 14rem;
  border-radius: 1rem;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0px 10px 100px -5px rgba($color: #000000, $alpha: 0.1);
  border: 2px solid rgba($color: #000000, $alpha: 0.1);
  position: absolute;
  z-index: 1000;
  margin-top: 1rem;
}
.custom-duration-filter {
  padding: 5px 1rem;
  border: 2px solid #3939e2;
  display: inline-block;
  border-radius: 2rem;
  cursor: pointer;
  margin-right: 2rem;
}
.custom-duration-items-container {
  width: 20rem;
  height: 20rem;
  border-radius: 1rem;
  background-color: #fff;
  padding: 1rem;
  box-shadow: 0px 10px 100px -5px rgba($color: #000000, $alpha: 0.1);
  border: 2px solid rgba($color: #000000, $alpha: 0.1);
  position: absolute;
  z-index: 1000;
  margin-top: 1rem;
}
.custom-violet-bg {
  background-color: #3939e2;
  color: #fff;
}
.custom-room-type {
  border: 2px solid #3939e2;
  display: inline-block;
  border-radius: 2rem;
  cursor: pointer;
  margin-right: 2rem;
  overflow: hidden;
  height: 3rem;
  width: 14rem;
}
.custom-line {
  height: 3rem;
  width: 1px;
  background-color: #3939e2;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.custom-room-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  width: 2rem;
  border: 1px solid #3939e2;
  line-height: 0;
  border-radius: 2rem;
  transition: 0.3s ease;
  font-size: 1.2rem;
  &:hover {
    background-color: #3939e2;
    color: #fff;
  }
}
.custom-reset {
  color: #e23955;
  background-color: rgba(226, 57, 85, 0.07);
  cursor: pointer;
  border: 1px solid #e23955;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  font-weight: 500;
  transition: 0.3s ease;
  &:hover {
    background-color: #e23955;
    color: #fff;
  }
}
.custom-apply {
  color: #1eb818;
  background-color: rgba(29, 184, 24, 0.07);
  cursor: pointer;
  border: 1px solid #1eb818;
  border-radius: 2rem;
  padding: 0.5rem 1rem;
  font-weight: 500;
  transition: 0.3s ease;
  &:hover {
    background-color: #1eb818;
    color: #fff;
  }
}
.custom-entire-private-room {
  &.active {
    color: #fff;
    background-color: #3939e2;
  }
}
.custom-switch {
  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
  }

  .switch input {
    display: none;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #3939e2;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #3939e2;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
}

@media only screen and (max-width: 578px) {
  .custom-listing-filter {
    padding: 5px 0.4rem;
    border: 2px solid #3939e2;
    display: inline-block;
    border-radius: 2rem;
    cursor: pointer;
    margin-right: 1.2rem;
    font-size: 0.7rem;
  }
}
</style>
