<template lang="">
  <div class="custom-container w-100">
    <div class="container d-flex justify-content-between align-items-center">
      <img
        :src="dots"
        class="custom-dot-image position-absolute"
        alt="no image available"
      />
      <div v-for="(item, index) in dummyData" :key="index">
        <Card
          :cardImage="item.image"
          :cardHeader="item.header"
          :cardText="item.text"
        />
      </div>
    </div>
  </div>
</template>
<script>
import Card from "../Card/Card.vue";
import cardImage1 from "@/assets/card-1.jpg";
import cardImage2 from "@/assets/card-2.jpg";
import cardImage3 from "@/assets/card-3.jpg";
import cardImage4 from "@/assets/card-4.jpg";
import dots from "@/assets/dots.png";
export default {
  data() {
    return {
      dummyData: [
        {
          header: "Are you relocating?",
          text: "Looking for a new home?",
          image: cardImage1,
        },
        {
          header: "Digital Nomads",
          text: "Your next stop recommendation",
          image: cardImage2,
        },
        {
          header: "Family Friendy",
          text: "Travelling with kids?",
          image: cardImage3,
        },
        {
          header: "Co-living",
          text: "Social & Open places",
          image: cardImage4,
        },
      ],
      dots,
    };
  },
  components: {
    Card,
  },
};
</script>
<style lang="scss" scoped>
.custom-container {
  height: 80vh;
  min-height: 80vh;
  position: relative;
  
  .container {
    width: 80%;
    margin: 0 auto;
    min-height: 80vh;
    height: 100%;
    flex-wrap: wrap;
    .custom-dot-image {
      right: 7%;
      height: 13rem;
      top: 3%;
      width: auto;
    }
  }
}

@media only screen and (max-width: 768px) {
  .custom-container {
    height: 200vh;
    min-height: 200vh;
    .container {
      width: 100%;
      margin: 0 auto;
      min-height: 100vh;
      height: 100%;
      flex-wrap: wrap;
      justify-content: center !important;
      .custom-dot-image {
        right: 7%;
        height: 13rem;
        top: 3%;
        width: auto;
      }
    }
  }
  
}
</style>

<style lang="scss">
  html{
    font-size: 14px;
  }
</style>
