<template>
  <div id="custom-big-menu" class="custom-big-menu">
    <div class="cross" @click="closeMenu">&#10005;</div>
    <div class="custom-container">
      <div class="custom-column">
        <div class="custom-heading">
          <span>
            <router-link
              @click="closeMenu"
              class="custom-hover-item"
              to="/"
              style="
                text-decoration: none;
                font-weight: 500;
                cursor: pointer;
                text-align: center;
                color: #212529;
              "
            >
              Home
            </router-link>
          </span>
        </div>
        <div class="custom-heading">
          <span>
            <router-link
              @click="closeMenu"
              class="custom-hover-item"
              to="/property"
              style="
                text-decoration: none;
                font-weight: 500;
                cursor: pointer;
                text-align: center;
                color: #212529;
              "
            >
              Find Homes
            </router-link>
          </span>
        </div>
        <div class="custom-heading" v-if="isSignedIn">
          <span>
            <router-link
              @click="closeMenu"
              class="custom-hover-item"
              to="/reservations"
              style="
                text-decoration: none;
                font-weight: 500;
                cursor: pointer;
                text-align: center;
                color: #212529;
              "
            >
              Reservations
            </router-link>
          </span>
        </div>
        <div class="custom-heading" v-if="!isSignedIn">
          <span>
            <router-link
              @click="closeMenu"
              class="custom-hover-item"
              to="/login"
              style="
                text-decoration: none;
                font-weight: 500;
                cursor: pointer;
                text-align: center;
                color: #212529;
              "
            >
              Sign in
            </router-link>
          </span>
        </div>
        <div class="custom-heading" v-if="!isSignedIn">
          <span>
            <router-link
              @click="closeMenu"
              class="custom-hover-item"
              to="/registration"
              style="
                text-decoration: none;
                font-weight: 500;
                cursor: pointer;
                text-align: center;
                color: #212529;
              "
            >
              Sign up
            </router-link>
          </span>
        </div>
        <div class="custom-heading" v-if="isSignedIn">
          <span>
            <router-link
              @click="closeMenu"
              class="custom-hover-item"
              to="/profile"
              style="
                text-decoration: none;
                font-weight: 500;
                cursor: pointer;
                text-align: center;
                color: #212529;
              "
            >
              Profile
            </router-link>
          </span>
        </div>
        <div class="custom-heading" @click="Logout" v-if="isSignedIn">
          <span class="custom-hover-item" >
              Logout
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isSignedIn: false,
    };
  },
  methods: {
    closeMenu() {
      document.getElementById("custom-big-menu").classList.remove("active");
    },
    Logout() {
      document.getElementById("custom-big-menu").classList.remove("active");
      localStorage.removeItem("app_access_token");
      localStorage.removeItem("app_refresh_token");
      window.location.href = "/";
    },
  },
  mounted() {
    if (localStorage.getItem("app_access_token")) {
      this.isSignedIn = true;
    }
  },
};
</script>

<style lang="scss" scoped>
.custom-big-menu {
  width: 100%;
  height: 100vh;
  z-index: 177;
  background-color: #111;
  pointer-events: none;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  transition: 0.5s ease;
  .custom-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  color: #fff;

    .custom-heading {
      text-align: center;
    }
  }
  &.active {
    pointer-events: auto;
    opacity: 1;
    transition: 0.5s ease;
  }
}
.cross {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 2rem;
  color: #fff;
}
a{
  color:#fff;
}
.custom-hover-item{
  color: #fff !important;
}
</style>
