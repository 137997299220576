<template>
  <div class="custom-places-container position-relative w-100 min-vh-100 ">
    <img class="custom-dots position-absolute" :src="dotsImg" alt="no image" />
    <div class="custom-container">
      <div class="text-center font-bold font-size-big-small mb-5">
        Places, you don't want to miss
      </div>
      <div class="grid-container pt-3">
        <div class="custom-image-1 position-relative" @click="routeToCity($event,places[0].city)">
          <img :src="places[0].image_url" v-if="places[0].image_url" alt="No image-available" />
          <div class="custom-text-wrapper w-100 position-absolute p-4">
            <div class="font-size-mid-small color-white font-semibold mb-1">
              {{ places[0].city }}
            </div>
          </div>
        </div>
        <div class="custom-image-2 position-relative" @click="routeToCity($event,places[1].city)">
          <img :src="places[1].image_url" v-if="places[1].image_url" alt="No image-available" />
          <div class="custom-text-wrapper w-100 position-absolute p-4">
            <div class="font-size-mid-small color-white font-semibold mb-1">
              {{ places[1].city }}
            </div>

          </div>
        </div>
        <div class="custom-image-3 position-relative" @click="routeToCity($event,places[2].city)">
          <img :src="places[2].image_url" v-if="places[2].image_url" alt="No image-available" />
          <div class="custom-text-wrapper w-100 position-absolute p-4">
            <div class="font-size-mid-small color-white font-semibold mb-1">
              {{ places[2].city }}
            </div>
    
          </div>
        </div>
        <div class="custom-image-4 position-relative" @click="routeToCity($event,places[3].city)">
          <img :src="places[3].image_url" v-if="places[3].image_url" alt="No image-available" />
          <div class="custom-text-wrapper w-100 position-absolute p-4">
            <div class="font-size-mid-small color-white font-semibold mb-1">
              {{ places[3].city }}
            </div>
    
          </div>
        </div>
        <div class="custom-image-5 position-relative" @click="routeToCity($event,places[4].city)">
          <img :src="places[4].image_url" v-if="places[4].image_url" alt="No image-available" />
          <div class="custom-text-wrapper w-100 position-absolute p-4">
            <div class="font-size-mid-small color-white font-semibold mb-1">
              {{ places[4].city }}
            </div>
    
          </div>
        </div>
        <div class="custom-image-6 position-relative" @click="routeToCity($event,places[5].city)">
          <img :src="places[5].image_url"  v-if="places[5].image_url" alt="No image-available" />
          <div class="custom-text-wrapper w-100 position-absolute p-4">
            <div class="font-size-mid-small color-white font-semibold mb-1">
              {{ places[5].city }}
            </div>
    
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import dotsImg from "@/assets/dots.png";
import placeImage1 from "@/assets/places-1.jpg";
import placeImage2 from "@/assets/places-2.jpg";
import placeImage3 from "@/assets/places-3.jpg";
import placeImage4 from "@/assets/places-4.jpg";
import placeImage5 from "@/assets/places-5.jpg";
import placeImage6 from "@/assets/places-6.jpg";
import {getTrendingLocations} from "@/utils/apis.js";
export default {
  data() {
    return {
      dotsImg,
      placeImage1,
      placeImage2,
      placeImage3,
      placeImage4,
      placeImage5,
      placeImage6,
      places :[]
    };
  },

  methods :{
    async useGetTrendingLocations(){

        try{
          const data = await getTrendingLocations();
          this.places = data.data;
          console.log('Places:', this.places);

        }
        catch(err){
          console.log(err);

        }
    },
    routeToCity(e,city){
      this.$router.push({
        path: "property",
        query: {
          city: city
        }
      });
    }

  },

  mounted(){
    this.useGetTrendingLocations();
  }



};
</script>
<style lang="scss" scoped>
.custom-places-container{
  min-height: 120vh;
}
.custom-container {
  width: 80%;
  padding: 4rem 0;
  margin: 0 auto;
  position: relative;
  height: 100%;
}
.custom-dots {
  left: 7%;
  height: 13rem;
  top: 13%;
  width: auto;
}
.grid-container {
  width: 100%;
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 1rem 1rem;
  grid-auto-flow: row;
  grid-template-areas:
    "custom-image-1 custom-image-1 custom-image-4 custom-image-5"
    "custom-image-2 custom-image-3 custom-image-4 custom-image-6";
}

.custom-image-1 {
  grid-area: custom-image-1;
  background-color: pink;
  overflow: hidden;
  border-radius: 10px;
  img {
    height: 17rem;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    border-radius: 10px;
    transition: 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    &:hover {
      transform: scale(1.2);
    }
  }
  .custom-text-wrapper {
    width: 100%;
    height: 6rem;
    background: linear-gradient(to bottom, transparent, rgba(#000, 0.75));
    z-index: 10;
    bottom: 0;
    border-radius: 0 0 10px 10px;
  }
}

.custom-image-2 {
  grid-area: custom-image-2;
  background-color: pink;
  overflow: hidden;
  border-radius: 10px;
  img {
    height: 17rem;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    border-radius: 10px;
    transition: 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    &:hover {
      transform: scale(1.2);
    }
  }
  .custom-text-wrapper {
    width: 100%;
    height: 6rem;
    background: linear-gradient(to bottom, transparent, rgba(#000, 0.75));
    z-index: 10;
    bottom: 0;
    border-radius: 0 0 10px 10px;
  }
}

.custom-image-3 {
  grid-area: custom-image-3;
  background-color: pink;
  overflow: hidden;
  border-radius: 10px;
  img {
    height: 17rem;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    border-radius: 10px;
    transition: 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    &:hover {
      transform: scale(1.2);
    }
  }
  .custom-text-wrapper {
    width: 100%;
    height: 6rem;
    background: linear-gradient(to bottom, transparent, rgba(#000, 0.75));
    z-index: 10;
    bottom: 0;
    border-radius: 0 0 10px 10px;
  }
}

.custom-image-4 {
  grid-area: custom-image-4;
  background-color: pink;
  overflow: hidden;
  border-radius: 10px;
  img {
    height: 100%;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    border-radius: 10px;
    transition: 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    &:hover {
      transform: scale(1.2);
    }
  }
  .custom-text-wrapper {
    width: 100%;
    height: 6rem;
    background: linear-gradient(to bottom, transparent, rgba(#000, 0.75));
    z-index: 10;
    bottom: 0;
    border-radius: 0 0 10px 10px;
  }
}

.custom-image-5 {
  grid-area: custom-image-5;
  background-color: pink;
  overflow: hidden;
  border-radius: 10px;
  img {
    height: 17rem;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    border-radius: 10px;
    transition: 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    &:hover {
      transform: scale(1.2);
    }
  }
  .custom-text-wrapper {
    width: 100%;
    height: 6rem;
    background: linear-gradient(to bottom, transparent, rgba(#000, 0.75));
    z-index: 10;
    bottom: 0;
    border-radius: 0 0 10px 10px;
  }
}

.custom-image-6 {
  grid-area: custom-image-6;
  background-color: pink;
  overflow: hidden;
  border-radius: 10px;
  img {
    height: 17rem;
    width: 100%;
    overflow: hidden;
    object-fit: cover;
    border-radius: 10px;
    transition: 1s cubic-bezier(0.215, 0.61, 0.355, 1);
    &:hover {
      transform: scale(1.2);
    }
  }
  .custom-text-wrapper {
    width: 100%;
    height: 6rem;
    background: linear-gradient(to bottom, transparent, rgba(#000, 0.75));
    z-index: 10;
    bottom: 0;
    border-radius: 0 0 10px 10px;
  }
}

@media only screen and (max-width: 768px) {
  .grid-container {
    display: grid; 
    grid-auto-columns: 1fr; 
    grid-template-columns: 1fr 1fr; 
    grid-template-rows: 1fr 1fr 1fr; 
    gap: 10px 10px; 
    grid-template-areas: 
      "custom-image-1 custom-image-2"
      "custom-image-3 custom-image-4"
      "custom-image-5 custom-image-6"; 
  }  
  .custom-container{
    width:95%;
    margin:0 auto;
  }
}

</style>
