<template>
<div id="id01" class="modal">
        <span
          onclick="document.getElementById('id01').style.display='none'"
          class="close"
          title="Close Modal"
          >×</span
        >
        <form class="modal-content" action="/action_page.php">
          <div class="container">
            <h1>Logout</h1>
            <p>Are you sure you want to Logout from your account?</p>

            <div class="clearfix">
              <div
                type="button"
                onclick="document.getElementById('id01').style.display='none'"
                class="cancelbtn"
              >
                Cancel
              </div>
              <div
                type="button"
                @click="logOut($event)"
                class="deletebtn"
              >
                Logout
              </div>
            </div>
          </div>
        </form>
      </div>
  
</template>

<script>
export default {
methods:{
    logOut(e) {
      e.preventDefault();
      document.getElementById('id01').style.display='none';
      localStorage.removeItem("app_access_token");
      localStorage.removeItem("app_refresh_token");
      sessionStorage.clear()

      this.changeIsSignedIn();
      this.$router.push("/");
      
    },
},
props:["changeIsSignedIn"]
}
</script>

<style>
.cancelbtn, .deletebtn {
  float: left;
  width: 50%;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Add a color to the cancel button */
.cancelbtn {
  background-color: #ccc;
  color: black;
}

/* Add a color to the delete button */
.deletebtn {
  background-color: #f44336;
  color: white;
}

/* Add padding and center-align text to the container */
.container {
  padding: 16px;
  text-align: center;
}
.modal {
  display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  overflow: auto; /* Enable scroll if needed */
  background-color: #0000006b;
  padding-top: 50px;
}

/* Modal Content/Box */
.modal-content {
  background-color: #fefefe;
  margin: 5% auto 15% auto; /* 5% from the top, 15% from the bottom and centered */
  border: 1px solid #888;
  width: 35rem; /* Could be more or less, depending on screen size */
}

/* Style the horizontal ruler */
hr {
  border: 1px solid #f1f1f1;
  margin-bottom: 25px;
}

/* The Modal Close Button (x) */
.close {
  position: absolute;
  right: 35px;
  top: 15px;
  font-size: 40px;
  font-weight: bold;
  color: #f1f1f1;
}

.close:hover,
.close:focus {
  color: #f44336;
  cursor: pointer;
}

/* Clear floats */
.clearfix::after {
  content: "";
  clear: both;
  display: table;
}
</style>