<template>
  <div class="home">
    <div class="d-flex flex-column">
      <Hero />
      <Cards />
      <Places />
      <TryHosting />
      <!-- <Others /> -->
      <Footer />
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Hero from "@/components/Hero/Hero.vue";
import Cards from "@/components/Cards/Cards.vue";
import Places from "@/components/Places/Places.vue";
import TryHosting from "@/components/TryHosting/TryHosting.vue";
import Others from "@/components/Others/Others.vue";
import Footer from "@/components/Footer/Footer.vue";

export default {
  name: "Home",
  components: {
    Hero,
    Cards,
    Places,
    TryHosting,
    Others,
    Footer,
  },
};
</script>
<style lang="scss" scoped></style>
